<template>
	<div class="popup">

        <div class="popup-header">
            {{this.order == null ? 'Order toevoegen' : 'Order ' + this.order.ordernumber + ' bewerken'}}
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <div v-if="form_error != ''" class="alert alert-danger">{{form_error}}</div>
            <div class="row">
                <div class="col col-6">
                    <div class="form">

                        <!-- dynamic ordermeta checkboxes -->
                        <div class="form-row">
                            <div class="col offset-3">
                                <!-- loop through items -->
                                <div v-for="meta in order_checkbox_metas" :key="meta.key" class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" :id="meta.key" v-model="order_meta[meta.key]" :disabled="!createAny('orders')">
                                    <label class="custom-control-label" :for="meta.key">{{ meta.label }}</label>
                                </div>
                            </div>
                        </div>

                        <!-- ordernummer -->
                        <div class="form-row">
                            <div class="col-3">
                                <label for="">Intern Ordernr</label>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" v-model="ordernumber" :disabled="!createAny('orders')">
                            </div>
                        </div>

                        <!-- dynamic ordermetas -->
                        <div class="form-row" v-for="meta in order_metas" :key="meta.key">
                            <div class="col-3">
                                <label :for="meta.key">{{meta.label}}</label>
                            </div>
                            <div class="col" :class="{'col-4': meta.type == 'date', 'col-2': meta.type == 'number'}">
                                <select v-if="meta.type == 'employee'" :id="meta.key" class="form-control" v-model="order_meta[meta.key]" :disabled="!createAny('orders')">
                                    <template v-for="(user) in users">
                                        <option v-if="user.showplanning" :key="user.id+'-'+meta.key" :value="user.id">{{user.firstname}} {{user.middlename}} {{user.lastname}}</option>
                                    </template>
                                </select>
                                <input v-else :type="meta.type" :placeholder="meta.label" :id="meta.key" class="form-control" v-model="order_meta[meta.key]" :disabled="!createAny('orders')">
                            </div>
                        </div>

                        <!-- description -->
                        <div class="form-row">
                            <div class="col-3">
                                <label for="text-2">Omschrijving</label>
                            </div>
                            <div class="col">
                                <textarea class="form-control" id="text-2" rows="4" v-model="description" :disabled="!createAny('orders')"></textarea>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col col-6">
                    <draggable v-model="order_rows" handle=".handle" @end="set_orderrow_sorting">

                        <div v-for="(orderrow, idx) in order_rows" :key="'orderrow' + idx.toString()" class="card">
                            
                            <!-- collapse header draggable handle -->
                            <div class="card-header handle" :class="planned_order_classes(orderrow)" :id="'rowtitle'+idx.toString()" v-b-toggle="'collapse-'+idx.toString()">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" :checked="is_row_completed(idx)" disabled>
                                    <label class="custom-control-label"></label>
                                </div>

                                <span title="Werkplek"><span class="mr-2">{{orderrow.sort}}</span><b class="mr-2">{{ workplace_by_id(orderrow.workplace_id).name }}</b></span><span title="Tijd" class="mr-2"><i>{{orderrow.duration}}</i></span><div v-html="order_planning_times(orderrow)"></div><span  class="mr-2">{{orderrow.remarks}}</span><span title="Uitbestedingsdatum" class="mr-2"><i>{{orderrow.meta['outsourcingdate']}}</i></span>
                                
                                <div class="order-status mr-2 mt-2 mb-2" :class="{new: order_meta.new, changed: orderrow.meta.changed, concept: orderrow.meta.concept}"></div>
                                <div v-if="orderrow.remarks != ''" class="order-comment mr-2 mt-2 mb-2"></div>
                            </div>

                            <!-- collapse content -->
                            <b-collapse :id="'collapse-'+idx.toString()" :visible="orderrow.id == row">
                                <div class="card-body">
                                    <div class="form">
                                        <div class="form-row">
                                            <div class="col offset-3">

                                                <!-- loop through orderrow checkboxes -->
                                                <div v-for="row_meta in order_row_checkbox_metas" :key="row_meta.key" class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" :id="idx.toString() + row_meta.key" v-model="orderrow.meta[row_meta.key]" :disabled="!createAny('orders')">
                                                    <label class="custom-control-label" :for="idx.toString() + row_meta.key">{{ row_meta.label }}</label>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- dynamic orderrow metas -->
                                        <div v-for="row_meta in order_row_metas" :key="row_meta.key">
                                            <div class="form-row" v-if="orderrow.meta[row_meta.toggle] || !row_meta.toggle">
                                                <div class="col-3">
                                                    <label :for="idx.toString() + row_meta.key">{{row_meta.label}}</label>
                                                </div>
                                                <div class="col" :class="{'col-4': row_meta.type == 'date', 'col-2': row_meta.type == 'number'}">
                                                    <input :type="row_meta.type" :placeholder="row_meta.label" :id="idx.toString() + row_meta.key" class="form-control" v-model="orderrow.meta[row_meta.key]" :disabled="!createAny('orders')">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <!-- duration -->
                                        <div class="form-row">
                                            <div class="col-3">
                                                <label :for="idx.toString() + 'duration'">Tijd</label>
                                            </div>
                                            <div class="col col-2">
                                                <input type="number" step="0.1" :id="idx.toString() + 'duration'" class="form-control" v-model="orderrow.duration" :disabled="!createAny('orders')">
                                            </div>
                                        </div>

                                        <!-- Workplace -->
                                        <div class="form-row">
                                            <div class="col-3">
                                                <label :for="idx.toString() + 'workplace'">Werkplek</label>
                                            </div>
                                            <div class="col">
                                                <select :id="idx.toString() + 'workplace'" class="form-control" v-model="orderrow.workplace_id" :disabled="!createAny('orders')">
                                                    <option v-for="workplace in workplaces" :value="workplace.id" :key="workplace.id">{{workplace.name}}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <!-- row remarks -->
                                        <div class="form-row">
                                            <div class="col-3">
                                                <label :for="idx.toString()+'remarks'">Opmerkingen</label>
                                            </div>
                                            <div class="col">
                                                <textarea class="form-control" :id="idx.toString()+'remarks'" rows="4" v-model="orderrow.remarks" :disabled="!createAny('orders')"></textarea>
                                            </div>
                                        </div>

                                        <div v-if="createAny('orders') && !order" class="form-row">
                                            <div class="col offset-3">
                                                <div class="btn btn-secondary" @click="remove_order_row(idx)"><i class="fas fa-trash"></i> Orderregel verwijderen</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </draggable>


                    <!-- btn orderregel toevoegen -->
                    <div v-if="createAny('orders')" @click="add_order_row" class="btn btn-primary float-right">Orderregel toevoegen</div>
                </div>

            </div>
        </div>

        <div class="popup-footer">
            <div @click="$emit('close')" class="btn btn-secondary">Sluiten</div>
            <div v-if="createAny('orders') && order == undefined" @click="save_order(false)" class="btn btn-primary">Opslaan en nieuwe</div>
            <div v-if="createAny('orders') && order != undefined" @click="save_order(true, true)" class="btn btn-primary">Dupliceren</div>
            <div v-if="createAny('orders')" @click="save_order(true)" class="btn btn-primary">Opslaan en sluiten</div>
        </div>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'
import axios from 'axios'
import Vue from 'vue'
import order_details_modal from './OrderDetailsModal'

export default {
    name: 'order-details-modal',
    data() {
        return {
            form_error: "",
            ordernumber: "",
            description: "",
            order_meta: {},
            order_rows: [],
            deleted_order_rows: []
        }
    },
    props: {
        order: Object,
        row: Number,
    },
    computed: {
        ...mapGetters([
            'workplace_by_id',
            'user_by_id',
            'user_background_style',
            'order_planning_by_workplace_id_and_date',
            'formatted_order_number',
            'planned_order_classes',

            'is_order_draggable',
            'is_hour_draggable',

            'days',

            'hour_status_unknown',
            'hour_status_available',
            'hour_status_blocked',
            'hour_status_free',

            'formatted_order_number',
            'planned_order_classes',
            'planned_order_padding',
            'font_classes',

            'createAny',
            'readAny',
            'updateAny',
            'deleteAny',

            'order_checkbox_metas',
            'order_metas',
            'order_row_checkbox_metas',
            'order_row_metas',
        ]),
        ...mapState([
            'users',
            'workplaces',
            'user_planning',
            'workplace_comments',
            'settings'
        ])
    },
    methods:{
        ...mapActions([
        ]),

        add_order_row() {
            var metas = {};
            this.order_rows.push(
            {
                sort: 0,
                workplace_id: this.workplaces[0].id,
                duration: 0.0,
                remarks: "",
                meta: metas
            });

            this.set_orderrow_sorting()
        },

        save_order(close, duplicate = false) {
            if (this.ordernumber == '') {
                this.form_error = 'Geen ordernummer ingevuld.'
            } else if (this.order_rows.length == 0) {
                this.form_error = 'Geen orderregels ingevuld.'
            } else if (this.order_rows.find((row) => row.duration == 0)) {
                this.form_error = 'Een of meer orderregels hebben geen tijd ingevuld.'
            } else {
                var order = {
                    'ordernumber': this.ordernumber,
                    'description': this.description,
                    'order_meta': this.order_meta,
                    'order_rows': JSON.parse(JSON.stringify(this.order_rows)),
                    'deleted_order_rows': this.deleted_order_rows
                };

                if (this.order) {
                    if (duplicate) {
                        for (var i = 0; i < order['order_rows'].length; ++i) {
                            order['order_rows'][i]['id'] = undefined
                        }
                    } else {
                        order.id = this.order.id
                    }
                }

                axios.put('/api/order', order).then((response) => {
                    this.form_error = ''
                    this.ordernumber = ''
                    this.description = ''
                    this.order_meta = { }

                    for (var i = 0; i < this.order_metas.length; i++) {
                        var key = this.order_metas[i].key
                        this.order_meta[key] = ''
                    }

                    this.order_rows = []
                    this.deleted_order_rows = []

                    if (duplicate) {
                        this.$modal.show(order_details_modal, {
                            order: response.data,
                        }, {
                            name: 'order-details-modal',
                            width: '1280px',
                            height: '800px'
                        })
                    }
                });

                this.$emit('close')

                if (!close) {
                    this.$modal.show(order_details_modal, {
                    }, {
                        name: 'order-details-modal',
                        width: '1280px',
                        height: '800px'
                    })
                }
            }
        },

        remove_order_row(idx) {
            var removed_rows = this.order_rows.splice(idx, 1)
            if (removed_rows.length == 1) {
                if (removed_rows[0].id) {
                    this.deleted_order_rows.push(removed_rows[0].id)
                }
            }

            this.set_orderrow_sorting()
        },

        set_orderrow_sorting() {
            for (var i = 0; i < this.order_rows.length; ++i) {
                this.order_rows[i].sort = (i + 2) * 10
            }
        },

        is_row_completed:function(idx) {
            if (this.order && this.order.rows.length > idx)  {
                return this.order.rows[idx].completed == this.order.rows[idx].duration
            }
            return false;
        },
        order_planning_times:function(orderrow) {
            if (this.order && this.order.rows && this.order.rows.length > 0) {
                var idx = this.order.rows.findIndex(function(item) {
                    return (item.id == orderrow.id);
                });

                // if (this.order && this.order.rows.length > idx) {
                if (idx> -1) {
                    var row = this.order.rows[idx];
                    return `<span title="Ingepland" class="mr-2"><i>${row.planned}</i></span><span title="Afgerond" class="mr-2"><i>${row.completed}</i></span>`
                }
            }
            return ""
        },
    },
    mounted() {
        var all_order_metas = this.settings.order_metas
        var all_orderrow_metas = this.settings.orderrow_metas

        var i = 0;

        if (this.order) {
            //set default order values
            if (Object.prototype.hasOwnProperty.call(this.order, 'ordernumber')) {
                this.ordernumber = this.order.ordernumber
            }
            if (Object.prototype.hasOwnProperty.call(this.order, 'description')) {
                this.description = this.order.description
            }

            //set order metas
            for (i = 0; i < all_order_metas.length; i++) {
                var meta_key = all_order_metas[i].key
                if (Object.prototype.hasOwnProperty.call(this.order, meta_key)) {
                    Vue.set(this.order_meta, meta_key, this.order[meta_key])
                }
            }

            //add order rows
            if (Object.prototype.hasOwnProperty.call(this.order, 'rows')) {
                for (i = 0; i < this.order.rows.length; i++) {
                    var row_data = this.order.rows[i];
                    var row = {
                        id: row_data.id,
                        sort: 0,
                        workplace_id: -1,
                        remarks: '',
                        meta: {}
                    };

                    //default values
                    if (Object.prototype.hasOwnProperty.call(row_data, 'sort')) {
                        row.sort = row_data.sort
                    }
                    if (Object.prototype.hasOwnProperty.call(row_data, 'workplace_id')) {
                        row.workplace_id = row_data.workplace_id
                    }
                    if (Object.prototype.hasOwnProperty.call(row_data, 'duration')) {
                        row.duration = row_data.duration
                    }
                    if (Object.prototype.hasOwnProperty.call(row_data, 'remarks')) {
                        row.remarks = row_data.remarks
                    }

                    //set row metas
                    for (var j = 0; j < all_orderrow_metas.length; j++) {
                        var row_meta_key = all_orderrow_metas[j].key
                        if (Object.prototype.hasOwnProperty.call(row_data, row_meta_key)) {
                            row.meta[row_meta_key] = row_data[row_meta_key]
                        } else {
                            row.meta[row_meta_key] = ""
                        }
                    }

                    this.order_rows.push(row)
                }
            }
        }
    },
    components: { draggable }
}
</script>