<template>
	<div class="popup">

        <div class="popup-header">
            Legenda
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">            
            <!-- class="collapse" -->
            <div id="legend">
                <!-- OPMERKING -->
                <div class="order">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label"></label>
                    </div>

                    <span class="order-number">Opmerking</span>
                    <!-- <span class="order-time">8.0</span> -->
                    <div class="order-status"></div>
                    <div class="order-comment"></div>
                </div>

                <!-- NIEUW -->
                <div class="order">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label"></label>
                    </div>

                    <span class="order-number">Nieuw</span>
                    <!-- <span class="order-time">8.0</span> -->
                    <div class="order-status new"></div>
                </div>

                <!-- CHANGED -->
                <div class="order">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label"></label>
                    </div>

                    <span class="order-number">Gewijzigd</span>
                    <!-- <span class="order-time">8.0</span> -->
                    <div class="order-status changed"></div>
                </div>

                <!-- CONCEPT -->
                <div class="order">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label"></label>
                    </div>

                    <span class="order-number">Proefmodel / Concept</span>
                    <!-- <span class="order-time">8.0</span> -->
                    <div class="order-status concept"></div>
                </div>

                <!-- AFGEROND -->
                <div class="order order-completed">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label"></label>
                    </div>

                    <span class="order-number">Afgerond</span>
                    <!-- <span class="order-time">8.0</span> -->
                    <div class="order-status"></div>
                </div>

                <!-- SPOED -->
                <div class="order order-priority">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label"></label>
                    </div>

                    <span class="order-number">Spoed</span>
                    <!-- <span class="order-time">8.0</span> -->
                    <div class="order-status"></div>
                </div>

                <!-- DELIVERY DATE -->
                <div class="order order-deliverydate-conflict">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label"></label>
                    </div>

                    <span class="order-number">Leverdatum conflict</span>
                    <!-- <span class="order-time">8.0</span> -->
                    <div class="order-status"></div>
                </div>

                <!-- OUTSOURCING DATE -->
                <div class="order order-outsourcingdate-conflict">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label"></label>
                    </div>

                    <span class="order-number">Uitbesteding conflict</span>
                    <!-- <span class="order-time">8.0</span> -->
                    <div class="order-status"></div>
                </div>

                <!-- WORKPLACE -->
                <div class="order order-ordering-conflict">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label"></label>
                    </div>

                    <span class="order-number">Volgorde conflict</span>
                    <!-- <span class="order-time">8.0</span> -->
                    <div class="order-status"></div>
                </div>

                <!-- WORKPLACE -->
                <div class="order order-workplace-conflict">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label"></label>
                    </div>

                    <span class="order-number">Werkplek conflict</span>
                    <!-- <span class="order-time">8.0</span> -->
                    <div class="order-status"></div>
                </div>

                <!-- OUTSOURCING -->
                <div class="order order-outsourcing">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label"></label>
                    </div>

                    <span class="order-number">Uitbesteding</span>
                    <!-- <span class="order-time">8.0</span> -->
                    <div class="order-status"></div>
                </div>

                <!-- PAY AHEAD -->
                <div class="order order-pay-ahead">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input">
                        <label class="custom-control-label"></label>
                    </div>

                    <span class="order-number">Vooruitbetaling</span>
                    <!-- <span class="order-time">8.0</span> -->
                    <div class="order-status"></div>
                </div>

            </div>
        </div>
        <div class="popup-footer">
            <div @click="$emit('close')" class="btn btn-secondary">Sluiten</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'legenda-modal',
}
</script>