import { mapGetters, mapState } from "vuex"

export default {
    data: function () {
        return {
			drag_over_element: '',
			polling: null
        }
    },
    computed: {
        ...mapGetters([
			'hour_status_unknown',
			'hour_status_available',
			'hour_status_blocked',
			'hour_status_free',
			'days'
		]),
		...mapState([
			'drag_data',
		])
	},
	created() {
		this.pollData()
	},
	beforeDestroy () {
		clearInterval(this.polling)
	},
    methods: {
		pollData () {
			this.polling = setInterval(() => {
				if (this.drag_data == null && this.drag_over_element != '') {
					this.drag_over_element = ''
				}
			}, 500)
		},
        on_enter_drag(event, id, date, key) {
			//skip when no drag data set
			if (!this.drag_data) return;

			if ( 
				( this.drag_data.type != 'orderrow' && (this.drag_data.id == id || id == null) && (this.drag_data.date == null || this.drag_data.date == date) ) ||
				( this.drag_data.type == 'orderrow' && ((date != null && key.includes('workplace')) || (date == null && key.includes('unscheduled')) || (date == null && key.includes('temp-workplace')) ) )
			) {
				this.drag_over_element = key
				event.preventDefault()
			}
		},
		on_over_drag(event, id, date) {
			//skip when no drag data set
			if (!this.drag_data) return;

			if ( 
				( this.drag_data.type != 'orderrow' && (this.drag_data.id == id || id == null) && (this.drag_data.date == null || this.drag_data.date == date) ) ||
				( this.drag_data.type == 'orderrow')
			) {
				event.preventDefault()
			}
		},
		on_leave_drag() {
			this.drag_over_element = ''
		},
		on_drop_item(targettype, id, date) {
			this.drag_over_element = ''

			//skip when no drag data set
			if (!this.drag_data) return;
			
			if (date == null || this.drag_data.date == null || date == this.drag_data.date) {

				if (this.drag_data.type == 'hour') {

					if (targettype == 'workplace') {
						this.$store.dispatch('plan_user_hour', {user_id: this.drag_data.id, date: this.drag_data.date, hour: this.drag_data.id2, type: id })
					}

					else if (targettype == 'user') {
						this.$store.dispatch('plan_user_hour', {user_id: this.drag_data.id, date: this.drag_data.date, hour: this.drag_data.id2, type: this.hour_status_available })
					}
				}

				if (this.drag_data.type == 'user') {
					var i;
					if (targettype == 'workplace') {
						this.$store.dispatch('plan_user_day', {user_id: this.drag_data.id, date: date, type: id })
					}
					else if (targettype == 'workplace-week') {
						var d = new Date(date.getTime())
						for (i = 0; i < 5; ++i) {
							this.$store.dispatch('plan_user_day', {user_id: this.drag_data.id, date: d, type: id })

							d.setDate(d.getDate() + 1)
							d = new Date(d.getTime())
						}
					}
					else if (targettype == 'user') {
						this.$store.dispatch('plan_user_day', {user_id: this.drag_data.id, date: date, type: this.hour_status_available })
					}

					else if (targettype == 'day') {
						this.$store.dispatch('plan_user_day', {user_id: this.drag_data.id, date: date, type: this.hour_status_free })
					}
					else if (targettype == 'week') {
						for (i = 0; i < 5; ++i) {
							this.$store.dispatch('plan_user_day', {user_id: this.drag_data.id, date: this.days[i], type: this.hour_status_free })
						}
					}
				}

				if (this.drag_data.type == 'orderrow') {
					if (targettype == 'workplace') {
						if (date == null && id == null) {
							this.$store.dispatch('remove_orderrow_planning', { planning_id: this.drag_data.id2 })
						} else {
							this.$store.dispatch('plan_orderrow', { workplace_id: id, orderrow_id: this.drag_data.id, date: date, planning_id: this.drag_data.id2 })
						}
					}
				}
			}
		},
    }
}