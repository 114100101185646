<template>
    <div class="order-settings">
        <div class="form">

            <!-- ORDERNUMMER -->
            <div class="card">
                <div class="card-header" id="ordernumber-template" v-b-toggle="'collapse-ordernumber-template'">
                    Ordernummer Template
                </div>
                <b-collapse id="collapse-ordernumber-template">
                    <div class="card-body">
                        <p>Stel hier in welke informatie je zichtbaar wil hebben op een 'ingeplande order'. Welke velden beschikbaar zijn zie je onderaan. Het is ook mogelijk om hierin HTML en styling toe te passen.</p>
                        <div class="form-row">
                            <div class="col-3">
                                <label for="">Template</label>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" v-model="settings.planned_order_mask" @blur="save_settings">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <ul>
                                    <li v-for="meta in available_order_columns" :key="meta.key">%{{meta.key}}%</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>

            <!-- INPLANNEN KOLOMMEN -->
            <div class="card">
                <div class="card-header" id="unscheduled-orders" v-b-toggle="'unscheduled-order-columns'">
                    Order plannen - Kolommen
                </div>
                <b-collapse id="unscheduled-order-columns">
                    <div class="card-body">
                        <p>Selecteer hier welke data / kolommen je wil tonen in het scherm 'Openstaande orderregels' (Inplannen)</p>
                        <draggable v-model="settings.unscheduled_orders_columns" handle=".handle" @end="save_settings">
                            <div v-for="(order_col, idx) in settings.unscheduled_orders_columns" v-bind:key="'col_' + idx.toString()" class="form-row">
                                <div class="col-1 handle">
                                    <font-awesome-icon icon="grip-lines" />
                                </div>
                                <div class="col-3">
                                    <select v-model="order_col.key" class="form-control" @change="order_col.label = available_order_columns.find(col => col.key == order_col.key).label; save_settings();">
                                        <option v-bind:value="meta.key" v-for="meta in available_order_columns" v-bind:key="meta.key">{{meta.label}}</option>
                                    </select>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" v-model="order_col.label" @blur="save_settings" />
                                </div>
                                <div class="col-3">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" v-bind:id="'sortable_'+idx.toString()" v-model="order_col.sortable" @change="save_settings">
                                        <label class="custom-control-label" v-bind:for="'sortable_'+idx.toString()">Sorteerbaar</label>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="btn btn-primary btn-sm" @click="settings.unscheduled_orders_columns.splice(idx, 1); save_settings();"><font-awesome-icon icon="trash"/></div>
                                </div>
                            </div>
                        </draggable>

                        <div class="form-row">
                            <div class="col"></div>
                            <div class="col-1">
                                <div class="btn btn-primary btn-sm" @click="settings.unscheduled_orders_columns.push({'key': '', 'label': 'label', 'sortable': 0 }); save_settings();"><font-awesome-icon icon="plus"/></div>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>

            <!-- TEMP-BAK INPLANNEN KOLOMMEN -->
            <div class="card">
                <div class="card-header" id="temp-orders" v-b-toggle="'temp-order-columns'">
                    Tijdelijke Opslag - Kolommen
                </div>
                <b-collapse id="temp-order-columns">
                    <div class="card-body">
                        <p>Selecteer hier welke data / kolommen je wil tonen in een scherm 'Tijdelijke opslag'. Je kan een tijdelijke opslag aanmaken door een Werkplek aan te maken en hierbij het vinkje 'tijdelijke opslag' aan te zetten.</p>
                        <draggable v-model="settings.temp_orders_columns" handle=".handle" @end="save_settings">
                            <div v-for="(order_col, idx) in settings.temp_orders_columns" v-bind:key="'col_' + idx.toString()" class="form-row">
                                <div class="col-1 handle">
                                    <font-awesome-icon icon="grip-lines" />
                                </div>
                                <div class="col-3">
                                    <select v-model="order_col.key" class="form-control" @change="order_col.label = available_order_columns.find(col => col.key == order_col.key).label; save_settings();">
                                        <option v-bind:value="meta.key" v-for="meta in available_order_columns" v-bind:key="meta.key">{{meta.label}}</option>
                                    </select>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" v-model="order_col.label" @blur="save_settings" />
                                </div>
                                <div class="col-3">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" v-bind:id="'tmp_sortable_'+idx.toString()" v-model="order_col.sortable" @change="save_settings">
                                        <label class="custom-control-label" v-bind:for="'tmp_sortable_'+idx.toString()">Sorteerbaar</label>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="btn btn-primary btn-sm" @click="settings.temp_orders_columns.splice(idx, 1); save_settings();"><font-awesome-icon icon="trash"/></div>
                                </div>
                            </div>
                        </draggable>

                        <div class="form-row">
                            <div class="col"></div>
                            <div class="col-1">
                                <div class="btn btn-primary btn-sm" @click="settings.temp_orders_columns.push({'key': '', 'label': 'label', 'sortable': 0 }); save_settings();"><font-awesome-icon icon="plus"/></div>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>

            <!-- ORDER METAS -->
            <div class="card">
                <div class="card-header" id="order-metas" v-b-toggle="'order-metas-toggle'">
                    Order velden
                </div>
                <b-collapse id="order-metas-toggle">
                    <div class="card-body">
                        <p>Het is mogelijk om 'custom' velden toe te voegen aan een order. Een custom veld bestaat uit een type, een key en een label.</p>
                        <draggable v-model="settings.order_metas" handle=".handle" @end="save_settings">
                            <div v-for="(order_meta, idx) in settings.order_metas" v-bind:key="'col_' + idx.toString()" class="form-row">
                                <div class="col-1 handle">
                                    <font-awesome-icon icon="grip-lines" />
                                </div>
                                <div class="col-3">
                                    <select v-model="order_meta.type" class="form-control" @change="save_settings" v-bind:disabled="order_meta.builtin">
                                        <option value="text">Tekst</option>
                                        <option value="date">Datum</option>
                                        <option value="number">Nummer</option>
                                        <option value="checkbox">Vinkje</option>
                                        <option value="employee">Medewerker</option>
                                    </select>
                                </div>
                                <div class="col-3">
                                    <input type="text" v-model="order_meta.key" class="form-control" @blur="save_settings" v-bind:disabled="order_meta.builtin">
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" v-model="order_meta.label" @blur="save_settings" v-bind:disabled="order_meta.builtin">
                                </div>
                                <div class="col-1">
                                    <div v-if="!order_meta.builtin" class="btn btn-primary btn-sm" @click="settings.order_metas.splice(idx, 1); save_settings();"><font-awesome-icon icon="trash"/></div>
                                </div>
                            </div>
                        </draggable>

                        <div class="form-row">
                            <div class="col"></div>
                            <div class="col-1">
                                <div class="btn btn-primary btn-sm" @click="settings.order_metas.push({'key': '', 'label': 'label', 'sortable': 0 }); save_settings();"><font-awesome-icon icon="plus"/></div>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>

            <!-- ORDERROW METAS -->
            <div class="card">
                <div class="card-header" id="orderrow-metas" v-b-toggle="'orderrow-metas-toggle'">
                    Orderregel velden
                </div>
                <b-collapse id="orderrow-metas-toggle">
                    <div class="card-body">
                        <p>Het is mogelijk om 'custom' velden toe te voegen aan een order. Een custom veld bestaat uit een type, een key en een label.</p>
                        <draggable v-model="settings.orderrow_metas" handle=".handle" @end="save_settings">
                            <div v-for="(order_meta, idx) in settings.orderrow_metas" v-bind:key="'col_' + idx.toString()" class="form-row">
                                <div class="col-1 handle">
                                    <font-awesome-icon icon="grip-lines" />
                                </div>
                                <div class="col-3">
                                    <select v-model="order_meta.type" class="form-control" @change="save_settings" v-bind:disabled="order_meta.builtin">
                                        <option value="text">Tekst</option>
                                        <option value="date">Datum</option>
                                        <option value="number">Nummer</option>
                                        <option value="checkbox">Vinkje</option>
                                        <option value="employee">Medewerker</option>
                                    </select>
                                </div>
                                <div class="col-3">
                                    <input type="text" v-model="order_meta.key" class="form-control" @blur="save_settings" v-bind:disabled="order_meta.builtin">
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" v-model="order_meta.label" @blur="save_settings" v-bind:disabled="order_meta.builtin">
                                </div>
                                <div class="col-1">
                                    <div v-if="!order_meta.builtin" class="btn btn-primary btn-sm" @click="settings.orderrow_metas.splice(idx, 1); save_settings();"><font-awesome-icon icon="trash"/></div>
                                </div>
                            </div>
                        </draggable>

                        <div class="form-row">
                            <div class="col"></div>
                            <div class="col-1">
                                <div class="btn btn-primary btn-sm" @click="settings.orderrow_metas.push({'key': '', 'label': 'label', 'sortable': 0 }); save_settings();"><font-awesome-icon icon="plus"/></div>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'

export default {
    name: 'order-settings-screen',
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters([
            'createAny',
            'readAny',
            'updateAny',
            'deleteAny',
        ]),
        ...mapState([
            'users',
            'workplaces',
            'user_planning',
            'workplace_comments',
            'settings'
        ]),
        available_order_columns: {
            get() {
                var metas = [
                    {
                        'key': 'ordernumber',
                        'label': 'Ordernummer',
                    },
                    {
                        'key': 'description',
                        'label': 'Omschrijving',
                    },
                    {
                        'key': 'remarks',
                        'label': 'Opmerking',
                    },
                    {
                        'key': 'workplace_id',
                        'label': 'Werkplek',
                    },
                    {
                        'key': 'total_remaining',
                        'label': 'Openstaand',
                    },
                    {
                        'key': 'total_duration',
                        'label': 'Totale tijd',
                    },
                    {
                        'key': 'total_planned',
                        'label': 'Ingeplanned',
                    },
                    {
                        'key': 'planning_duration',
                        'label': 'Tijd',
                    },
                ]
                metas = metas.concat(this.settings.order_metas)
                metas = metas.concat(this.settings.orderrow_metas)

                return metas;
            }
        },
    },
    methods:{
        ...mapActions([
            'save_settings'
        ]),
    },
    components: { draggable }
}
</script>