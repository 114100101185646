<template>
	<div class="row">
		<!-- EMPLOYEE NAME -->
		<div class="first-col"
			:draggable="is_user_draggable(user_id)" 
			@dragstart="begin_drag_item({ type: 'user', id: user_id, date: null })"
			@dragend="end_drag_item()"
			@click="search_user_projects(user_id)"
			>
			<div class="employee-color" :style="user_background_style(user_id)"></div>
			<div class="employee-name">{{ user_by_id(user_id).firstname }}</div>
		</div>

		<!-- DAYS -->
		<div v-for="(date, day_index) in days" 
			:key="get_user_day_key(user_id, date)"
			class="day" :class="{ 'birthday': is_birthday(date) }"

			@dragenter="on_enter_drag($event, user_id, date, get_user_day_key(user_id, date))"
			@dragover="on_enter_drag($event, user_id, date, get_user_day_key(user_id, date))"
			>

			<div
				class="overlay"
				:class="{ 'dragover': drag_over_element == get_user_day_key(user_id, date)  }" 
				@dragover="on_over_drag($event, user_id, date)"
				@dragleave="on_leave_drag()"
				@drop="on_drop_item('user', user_id, date)"
			/>

			<!-- HOURS -->
			<div v-for="(hour_value, hour_index) in user_planning_by_date(user_id, date)" 
				:key="'user-'+user_id+'-day-'+ day_index +'-hour-'+hour_index" 
				:style="user_background_style(user_id)"
				:title="user_by_id(user_id).firstname"
				:class="{'hour-empty': hour_value > hour_status_free, 'hour-free': hour_value == hour_status_free, 'hour-blocked': hour_value == hour_status_blocked}" class="hour" 
				:draggable="is_hour_draggable(hour_value)" 

				@dragstart="begin_drag_item({type: 'hour', id: user_id, date: date, id2:hour_index})"
				@dragend="end_drag_item()"
				@dblclick="plan_user_hour({user_id: user_id, date: new Date(date), hour:hour_index, type: 0})"
			/>

		</div>
	</div>
</template>

<script>
import DragDropTarget from './DragMixin'
import { mapGetters, mapActions } from 'vuex'
import search_modal from './modals/SearchModal'
export default {
	name:'employee',
	data() {
		return { }
	},
	props: {
		user_id: Number
	},
	computed: {
		...mapGetters([
			'days',
			
			'user_by_id',
			'user_background_style',
      
			'is_hour_draggable',
			'is_user_draggable',
			
			'hour_status_unknown',
			'hour_status_available',
			'hour_status_blocked',
			'hour_status_free',
			
			'user_planning_by_date'
		]),
	},
	mixins: [DragDropTarget],
	methods: {
		is_birthday(date) {
			var birthdate = new Date(this.user_by_id(this.user_id).birthday)
			return birthdate.getMonth() + '-' + birthdate.getDate() == date.getMonth() + '-' + date.getDate();
		},

		...mapActions([
			'plan_user_hour',
			'begin_drag_item',
			'end_drag_item',
			'search'
		]),

		get_user_day_key(user_id, date) {
			return 'user-' + user_id + '-day-'+date.getTime()
		},

		search_user_projects(user_id) {
			this.$store.commit('searchvalue', '')
			this.$store.commit('searchstatus', [0])
			this.$store.commit('searchdate', '')
			this.$store.commit('searchmanager', user_id)
			this.search();

			this.$modal.hide('search-modal')
			this.$modal.show(search_modal, {}, {
					name: 'search-modal'
			})
		},
	}
}
</script>