<template>
	<div class="popup">

        <div class="popup-header">
            Medewerkers
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <draggable v-model="sorted_users" handle=".handle">
                <div class="form-group row" v-for="user in sorted_users"  v-bind:key="'edit-user-row-'+user.id">
                    <div class="col-1 handle">
                        <font-awesome-icon icon="grip-lines" />
                    </div>

                    <div class="col-10" @click="edit_user(user)">
                        {{user.firstname}} {{user.middlename}} {{user.lastname}}
                    </div>

                    <div class="col-1">
                        <a href="javascript:;" class="float-right" @click="delete_user(user.id)"><font-awesome-icon icon="trash"/></a>
                    </div>

                </div>
            </draggable>
        </div>

        <div class="popup-footer">
            <div @click="edit_user({ id: 0 })" class="btn btn-primary">Toevoegen</div>
            <div @click="$emit('close')" class="btn btn-secondary">Sluiten</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'
import user_details_modal from './UserModal'

export default {
    name: 'users-modal',
    data() {
        return {
        }
    },
    computed: {
        sorted_users: {
            get: function() {
                return this.users
            },
            set: function(val) {
                //update sort of each user
                val.forEach(user => {
                    user.sort = val.indexOf(user)
                })
                
                //store in Vuex
                this.$store.commit('users', val)

                //save to database
                this.users.forEach(user => {
                    this.save_user(user.id)
                });
            }
        },
        ...mapGetters([
            'createAny',
            'readAny',
            'updateAny',
            'deleteAny',
        ]),
        ...mapState([
            'users',
            'workplaces',
            'user_planning',
            'workplace_comments',
            'settings'
        ])
    },
    methods:{
        ...mapActions([
            'delete_user',
            'save_user'
        ]),
        edit_user(user) {
            this.$modal.hide('user-details-modal')
            this.$modal.show(user_details_modal, {
                user_id: user.id,
            }, {
                name: 'user-details-modal',
                width: '800px'
            })

            this.$emit('close')
        },
    },
    components: { draggable }
}
</script>