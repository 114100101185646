<template>
    <div class="popup">

        <div class="popup-header">
            Order {{ orderrow_by_orderrow_id(orderrow_id).ordernumber }} - Regel {{ orderrow_by_orderrow_id(orderrow_id).sort }} uitsmeren
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <div class="row">
                <div class="col-3">Order</div>
                <div class="col">{{ orderrow_by_orderrow_id(orderrow_id).ordernumber }}</div>
            </div>
            <div class="row">
                <div class="col-3">Omschrijving</div>
                <div class="col">{{ orderrow_by_orderrow_id(orderrow_id).description }}</div>
            </div>
            <div class="row">
                <div class="col-3">Orderregel</div>
                <div class="col">{{ orderrow_by_orderrow_id(orderrow_id).sort }} - {{ workplace_by_id(orderrow_by_orderrow_id(orderrow_id).workplace_id).name }}</div>
            </div>

            <hr/>

            <div class="form">

                <div class="form-row">
                  <div class="col-3">
                    <label for="startdate">Startdatum</label>
                  </div>
                  <div class="col col-4">
                    <input type="date" id="startdate" class="form-control" v-model="startdate">
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-3">
                    <label for="hours">Uren per dag</label><br/>
                  </div>
                  <div class="col col-3">
                    <input type="number" step="0.1" id="hours" class="form-control mb-0" v-model="hours">
                    <small class="form-text text-muted">
                        <div class="row">
                            <div class="col-6">Totale tijd</div><div class="col">{{ orderrow_by_orderrow_id(orderrow_id).total_duration }}</div>
                        </div>
                        <div class="row">
                            <div class="col-6">Reeds geplanned</div><div class="col">{{ orderrow_by_orderrow_id(orderrow_id).total_planned }}</div>
                        </div>
                        <div class="row">
                            <div class="col-6">Openstaand</div><div class="col">{{ orderrow_by_orderrow_id(orderrow_id).total_remaining }}</div>
                        </div>
                    </small>
                  </div>
                </div>
                <hr/>
                <div class="form-row">
                    <div v-for="(workplace) in visible_workplaces" :key="'visible-workplace-'+workplace.id" class="col col-3">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" v-bind:id="'workplace-visible-checkbox-'+workplace.id" :value="workplace.id" v-model="selected_workplaces">
                            <label class="custom-control-label" v-bind:for="'workplace-visible-checkbox-'+workplace.id">{{workplace.name}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup-footer">
            <div @click="spread_orderrow({ hours: hours, date: startdate, orderrow_id: orderrow_id, workplaces: selected_workplaces }); $emit('close')" class="btn btn-primary">Uitsmeren</div>
            <div @click="$emit('close')" class="btn btn-secondary">Sluiten</div>
        </div>
    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'spread-order-dialog',
  data() {
    return {
      selected_workplaces: [],
      startdate: "",
      hours: 0
    }
  },
  props: {
      orderrow_id: Number,
  },
  computed: {
    ...mapGetters([
        'visible_workplaces',
        'orderrow_by_orderrow_id',
        'workplace_by_id'
    ]),
  },
  methods: {
    ...mapActions([
      'spread_orderrow'
    ])
  }
}
</script>