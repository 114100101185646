<template>
	<div class="popup">

        <div class="popup-header">
            {{ workplace_by_id(workplace_id).name }}
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <form class="form">
                <div class="form-row">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="show-archived" v-model="show_archived">
                        <label class="custom-control-label" for="show-archived">Toon gearchiveerde medewerkers</label>
                    </div>
                </div>
            </form>
            <div class="calendar">
                <simplebar class="workplaces-scrollbar" data-simplebar-auto-hide="false">
                    <div class="workplaces">
                        <Workplace v-for="(i, index) in parseInt(settings.workplace_overview_weeks)" :key="'workplace-overview-row-'+workplace_id+'-'+i" :workplace_id="workplace_id" :datesList="days.slice(parseInt(index * 6), parseInt(index * 6) + 6)" :label="'Week ' + (days[parseInt(index * 6)].getWeekNumber()) + ' - ' + days[parseInt(index * 6)].getFullYear()" :show_archived="show_archived" />
                    </div>
                </simplebar>
            </div>
        </div>

        <div class="popup-footer">
            <div @click="$emit('close')" class="btn btn-secondary">Sluiten</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
    name: 'workplace-overview-modal',
    // mixins: [DragDropTarget],
    data() {
        return {
            show_archived: true
        }
    },
    props: {
        workplace_id: Number
    },
    computed: {
        ...mapGetters([
            'workplace_by_id',
            'days'
        ]),
        ...mapState([
            'settings',
            'date'
        ]),
        days: function() {
            var cols = []
            var d = new Date(this.date)
            d.setHours(0,0,0,0)
            for (var i = 0; i < parseInt(this.settings.workplace_overview_weeks * 7); i++) {
                if (d.getDay() == 0) {
                    d.setDate(d.getDate() + 1)
                }

                cols.push(new Date(d.getTime()))

                d.setDate(d.getDate() + 1)
                d = new Date(d.getTime())
            }
            return cols
        }
    },
    watch: {
    },
    mounted() {
        setTimeout(() => {
            this.$nextTick(() => this.$store.commit('num_columns', parseInt(this.settings.workplace_overview_weeks) * 7 ))
            this.$nextTick(() => this.$store.dispatch('get_planning', { date: this.$store.state.date, num_columns: this.$store.state.num_columns}))
        }, 100)
    },
    beforeDestroy() {
        this.$store.commit('num_columns', Math.floor((window.innerWidth - 124) / 144) + 1 )
    },
    methods:{
    },
    components: { simplebar }
}
</script>