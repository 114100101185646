<template>
    <div class="popup">

        <div class="popup-header">
            Zoeken
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <form class="form">
                <div class="form-row">
                    <div class="col-4">
                        <label for="">Ordernummer</label>
                    </div>
                    <div class="col">
                        <input ref="searchfield" type="text" class="form-control" v-model="searchvalue" @keypress.enter="$event.preventDefault(); search();">
                    </div>
                </div>
                
                <div class="form-row">
                    <div class="col-4">
                        <label for="">Projectleider</label>
                    </div>
                    <div class="col">
                        <select v-model="searchmanager" class="form-control">
                            <option value="0">Selecteer</option>
                            <option v-for="user in users" :key="user.id" :value="user.id">{{user.firstname}} {{user.middlename}} {{user.lastname}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-4">
                        <label for="">Status</label>
                    </div>
                    <div class="col">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" value="0" id="openstaand" v-model="searchstatus">
                            <label class="custom-control-label" for="openstaand">Openstaand</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" value="1" id="afgerond" v-model="searchstatus">
                            <label class="custom-control-label" for="afgerond">Afgerond</label>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-4">
                        <label for="">ingepland op of voor </label>
                        <!-- <b>{{ date_day(before_date) + ' ' + before_date.getDate() + ' ' + date_month(before_date) }}</b> -->
                    </div>
                    <div class="col">
                        <input ref="searchdate" type="date" class="form-control" v-model="searchdate">
                    </div>
                </div>
                
                <div class="form-row mb-4 mr-0">
                    <div class="col-12">
                        <div @click="search()" class="btn btn-primary float-right">Zoeken</div>
                        <div v-if="createAny('orders') && searchresults.length > 0 && searchresults.length <= perPage" @click="mark_all_complete()" class="btn btn-primary float-right mr-4">Markeer als afgerond</div>
                    </div>
                </div>
                
              </form>

              <b-pagination v-model="currentPage" :total-rows="searchresults.length" :per-page="perPage" aria-controls="search-results-table"></b-pagination>
              <b-table id="search-results-table" small striped hover :items="searchresults" :fields="fields" primary-key="planning_id" :tbody-tr-class="'unscheduled-orderrow'" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :per-page="perPage" :current-page="currentPage" :busy="searching">
                <template v-slot:table-busy>
                    <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>&nbsp;Bezig met zoeken...</strong>
                    </div>
                </template>
                
                <template v-slot:cell(date)="row">
                    {{ date_day(new Date(row.value)) + ' ' + new Date(row.value).getDate() + ' ' + date_month(new Date(row.value)) }}
                </template>

                <template v-slot:cell(completed)="row">
                    <font-awesome-icon v-if="row.value" icon="check" />
                </template>

                <template v-slot:cell(planning_workplace_id)="row">
                    {{ workplace_by_id(row.value ).name }} {{ workplace_by_id(row.value ).archived == 1 ? '(Gearchiveerd)' : '' }}
                </template>

                <template v-slot:cell(actions)="row">
                    <div class="btn-group ml-auto" role="group" aria-label="rowtools">
                        <div @click="jump_to_item(row.item)" class="btn btn-primary btn-sm"><font-awesome-icon icon="arrow-circle-right" style="width: 20px" /></div>
                        <div @click="show_order_details(row.item)" class="btn btn-primary btn-sm"><font-awesome-icon icon="info" style="width: 20px" /></div>
                    </div>
                </template>

              </b-table>
        </div>

        <div class="popup-footer">
            <div @click="$emit('close'); $store.dispatch('clear_search');" class="btn btn-secondary">Sluiten</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import axios from 'axios'
import order_details_modal from './OrderDetailsModal'
import workplace_overview_modal from './WorkplaceOverviewModal'

export default {
    name: 'search-modal',
    data() {
        return {
            loading: false,
            currentPage: 1,
            perPage: 25,
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            // items: [],
            fields: [
                {
                    key: "completed",
                    label: "",
                    sortable: 1
                },
                {
                    key: "date",
                    label: "Datum",
                    sortable: 1
                },
                {
                    key: "planning_workplace_id",
                    label: "Werkplek",
                    sortable: 1
                },
                {
                    key: "ordernumber",
                    label: "Ordernummer",
                    sortable: 1
                },
                {
                    key: "actions",
                    label: "",
                    sortable: false
                }
            ]
        }
    },
    computed: {
        searchvalue: {
            get: function() {
                return this.$store.state.searchvalue
            },
            set: function(value) {
                return this.$store.commit('searchvalue', value)
            }
        },
        searchstatus: {
            get: function() {
                return this.$store.state.searchstatus
            },
            set: function(value) {
                return this.$store.commit('searchstatus', value)
            }
        },
        searchdate: {
            get: function() {
                return this.$store.state.searchdate
            },
            set: function(value) {
                return this.$store.commit('searchdate', value)
            }
        },
        searchmanager: {
            get: function() {
                return this.$store.state.searchmanager
            },
            set: function(value) {
                return this.$store.commit('searchmanager', value)
            }
        },
        
        ...mapGetters([
            'createAny',
            'readAny',
            'updateAny',
            'deleteAny',
            'workplace_by_id',
            'date_day',
            'date_month',
        ]),
        ...mapState([
            'users',
            'workplaces',
            'user_planning',
            'workplace_comments',
            'settings',
            'searchresults',
            'searching'
        ]),
    },
    methods:{
        ...mapActions([
            'search'
        ]),
        jump_to_item(item) {
            var date = new Date(item.date)
            var d = new Date(date.getFullYear(), date.getMonth(), date.getDate())
            var offset = d.getDay() - 1
            var newDate = d.getDate() - offset
            d.setDate(newDate)

            this.$store.dispatch('set_date', { date: d })
            this.$store.commit('marked_orderplanning_id', item.planning_id)

            if (this.workplace_by_id(item.planning_workplace_id).archived == 1) {
                this.$modal.hide('workplace-overview-modal')
                this.$modal.show(workplace_overview_modal, {
                    workplace_id: item.planning_workplace_id
                }, {
                    name: 'workplace-overview-modal',
                    width: '1036px'
                })
            }

            this.$emit('close')
        },
        show_order_details(item) {
            axios.get('/api/order/' + item.order_id).then((response) => {
                // this.$modal.hide('order-details-modal')
                this.$modal.show(order_details_modal, {
                    order: response.data,
                    row: item.orderrow_id
                }, {
                    name: 'order-details-modal',
                    width: '1280px',
                    height: '800px'
                })
            })
        },
        mark_all_complete() {
            if (this.createAny('orderplanning')) {
                if (confirm('Weet je zeker dat je alle zoekresultaten als \'afgerond\' wil markeren?')) {
                    for (var i = 0; i < this.searchresults.length; ++i) {
                        if (!this.searchresults[i].completed) {
                            this.searchresults[i].completed = 1
                        }
                    }
                    axios.patch('/api/orderrows/planning/setcomplete', this.searchresults)
                }
            }
        },
    },
    created: function() {
        setTimeout(() => {
            this.$nextTick(() => this.$refs.searchfield.focus()) // this works great, just watch out with going to fast !!!
            this.$nextTick(() => this.$refs.searchfield.select())
        }, 100)
    },
    mounted() {
    },
    components: { }
}
</script>