<template>
    <div class="order-settings">
        <div class="form">

            <!-- PDC Path -->
            <div class="card">
                <div class="card-header" id="pdc-xml-location" v-b-toggle="'collapse-pdc-xml-location'">
                    PDC XML Locatie
                </div>
                <b-collapse id="collapse-pdc-xml-location">
                    <div class="card-body">
                        <p>Geef hier de locatie op van de PDC XML-bestanden. Deze locatie moet toegankelijk zijn voor de server.</p>
                        <div class="form-row">
                            <div class="col-3">
                                <label for="">Locatie</label>
                            </div>
                            <div class="col">
                                <input type="text" class="form-control" v-model="settings.pdc_import_path" @blur="save_settings">
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>

            <!-- Order Fields -->
            <div class="card">
                <div class="card-header" id="pdc-order-fields" v-b-toggle="'collapse-pdc-order-fields'">
                    Order velden
                </div>
                <b-collapse id="collapse-pdc-order-fields">
                    <div class="card-body">
                        <p>Geef hier aan welke XML velden (Links) van de order er gekoppeld moeten worden aan welke velden binnen de Plantool (Rechts).</p>
                        <div v-for="(pdc_order_field, idx) in settings.pdc_order_fields" v-bind:key="'pdc_order_field_col_' + idx.toString()" class="form-row">
                            <div class="col-5">
                                <input type="text" class="form-control" v-model="pdc_order_field.pdc_key" @change="save_settings" />
                            </div>
                            <div class="col-6">
                                <select v-model="pdc_order_field.order_key" @change="save_settings" class="form-control">
                                    <option v-for="(order_meta, idx) in order_fields" :key="'pdc_order_meta_select'+idx" :value="order_meta.key">{{order_meta.label}}</option>
                                </select>
                            </div>

                            <div class="col-1">
                                <div @click="settings.pdc_order_fields.splice(idx, 1); save_settings();"><font-awesome-icon icon="trash"/></div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-1"></div>
                            <div @click="settings.pdc_order_fields.push({})" class="btn btn-primary col">Kolom toevoegen</div>
                            <div class="col"></div>
                        </div>
                    </div>
                </b-collapse>
            </div>

            <!-- Orderrow Fields -->
            <div class="card">
                <div class="card-header" id="pdc-orderrow-fields" v-b-toggle="'collapse-pdc-orderrow-fields'">
                    Orderregel velden
                </div>
                <b-collapse id="collapse-pdc-orderrow-fields">
                    <div class="card-body">
                        <p>Geef hier aan welke XML velden (Links) van de orderregel / bewerking er gekoppeld moeten worden aan welke velden binnen de Plantool (Rechts).</p>
                        <div v-for="(pdc_orderrow_field, idx) in settings.pdc_orderrow_fields" v-bind:key="'pdc_orderrow_field_col_' + idx.toString()" class="form-row">
                            <div class="col-5">
                                <input type="text" class="form-control" v-model="pdc_orderrow_field.pdc_key" @change="save_settings" />
                            </div>
                            <div class="col-6">
                                <select v-model="pdc_orderrow_field.order_key" @change="save_settings" class="form-control">
                                    <option v-for="(orderrow, idx) in orderrow_fields" :key="'pdc_orderrow_meta_select'+idx" :value="orderrow.key">{{orderrow.label}}</option>
                                </select>
                            </div>

                            <div class="col-1">
                                <div @click="settings.pdc_orderrow_fields.splice(idx, 1); save_settings();"><font-awesome-icon icon="trash"/></div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-1"></div>
                            <div @click="settings.pdc_orderrow_fields.push({})" class="btn btn-primary col">Kolom toevoegen</div>
                            <div class="col"></div>
                        </div>
                    </div>
                </b-collapse>
            </div>

            <!-- Workplace Fields -->
            <div class="card">
                <div class="card-header" id="pdc-workplace-fields" v-b-toggle="'collapse-pdc-workplace-fields'">
                    Werkplek koppelingen
                </div>
                <b-collapse id="collapse-pdc-workplace-fields">
                    <div class="card-body">
                        <p>Geef hier aan welke bewerking er gekoppeld moet worden aan welke werkplek binnen de Plantool. In de laatste kolom kan een 'minimale tijd' aangegeven worden. Als de bewerkings-tijd in de XML kleiner is dan de hier opgegeven tijd, wordt de regel niet geimporteerd.</p>
                        <div v-for="(pdc_workplace_link, idx) in settings.pdc_workplace_links" v-bind:key="'pdc_workplace_link_col_' + idx.toString()" class="form-row">
                            <div class="col-4">
                                <input type="text" class="form-control" v-model="pdc_workplace_link.pdc_key" @change="save_settings" />
                            </div>
                            <div class="col-5">
                                <select v-model="pdc_workplace_link.workplace_id" @change="save_settings" class="form-control">
                                    <option v-for="(workplace, idx) in workplaces" :key="'pdc_workplace_links_select'+idx" :value="workplace.id">{{workplace.name}}</option>
                                </select>
                            </div>
                            <div class="col-2">
                                <input type="number" class="form-control" v-model="pdc_workplace_link.min_duration" @change="save_settings" />
                            </div>
                            <div class="col-1">
                                <div @click="settings.pdc_workplace_links.splice(idx, 1); save_settings();"><font-awesome-icon icon="trash"/></div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-1"></div>
                            <div @click="settings.pdc_workplace_links.push({})" class="btn btn-primary col">Kolom toevoegen</div>
                            <div class="col"></div>
                        </div>
                    </div>
                </b-collapse>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
// import draggable from 'vuedraggable'

export default {
    name: 'pdc-settings-screen',
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters([
            'createAny',
            'readAny',
            'updateAny',
            'deleteAny',
        ]),
        ...mapState([
            'users',
            'workplaces',
            'user_planning',
            'workplace_comments',
            'settings'
        ]),

        order_fields: {
            get() {
                var fields = [
                    {
                        'key': 'ordernumber',
                        'label': 'Ordernummer',
                    },
                    {
                        'key': 'description',
                        'label': 'Omschrijving',
                    },
                ];
                fields = fields.concat(this.settings.order_metas);
                return fields;
            }
        },

        orderrow_fields: {
            get() {
                var fields = [
                    {
                        'key': 'workplace_id',
                        'label': 'Werkplek',
                    },
                    {
                        'key': 'sort',
                        'label': 'Regelnummer',
                    },
                    {
                        'key': 'duration',
                        'label': 'Tijd',
                    },
                    {
                        'key': 'remarks',
                        'label': 'Opmerkingen',
                    },
                ];
                fields = fields.concat(this.settings.orderrow_metas);
                return fields;
            }
        }

    },
    methods:{
        ...mapActions([
            'save_settings'
        ]),
    },
    components: {  }
}
</script>