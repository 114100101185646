<template>
	<div class="popup">

        <div class="popup-header">
            Werkplekken
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <draggable v-model="sorted_workplaces" handle=".handle">
                <div class="form-group row" v-for="workplace in sorted_workplaces"  v-bind:key="'edit-workplace-row-'+workplace.id">
                    <div class="col-1 handle">
                        <font-awesome-icon icon="grip-lines" />
                    </div>

                    <div class="col-10" @click="edit_workplace(workplace)">
                        {{workplace.name}} <em v-if="workplace.archived">- Gearchiveerd</em>
                    </div>

                    <div class="col-1">
                        <a href="javascript:;" class="float-right" @click="delete_workplace(workplace.id)"><font-awesome-icon icon="trash"/></a>
                    </div>

                </div>
            </draggable>
        </div>

        <div class="popup-footer">
            <div @click="edit_workplace({id: 0})" class="btn btn-primary">Toevoegen</div>
            <div @click="$emit('close')" class="btn btn-secondary">Sluiten</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'
import workplace_details_modal from './WorkplaceModal'

export default {
    name: 'workplaces-modal',
    data() {
        return {
        }
    },
    computed: {
        sorted_workplaces: {
            get: function() {
                return this.workplaces
            },
            set: function(val) {
                //update sort of each workplace
                val.forEach(workplace => {
                    workplace.sort = val.indexOf(workplace)
                })
                
                //store in Vuex
                this.$store.commit('workplaces', val)

                //save to database
                this.workplaces.forEach(workplace => {
                    this.save_workplace(workplace.id)
                });
            }
        },
        ...mapGetters([
            'createAny',
            'readAny',
            'updateAny',
            'deleteAny',
        ]),
        ...mapState([
            'users',
            'workplaces',
            'user_planning',
            'workplace_comments',
            'settings'
        ])
    },
    methods:{
        ...mapActions([
            'delete_workplace',
            'save_workplace'
        ]),
        edit_workplace(workplace) {
            this.$modal.hide('workplace-details-modal')
            this.$modal.show(workplace_details_modal, {
                workplace_id: workplace.id,
            }, {
                name: 'workplace-details-modal', 
                width: '800px'
            })

            this.$emit('close')
        },
    },
    components: { draggable }
}
</script>