<template>
	<div class="popup">

        <div class="popup-header">
            Instellingen
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <b-tabs>
                <b-tab title="Persoonlijke instellingen" active>
                    <PersonalSettingsScreen></PersonalSettingsScreen>
                </b-tab>

                <b-tab v-if="createAny('settings')" title="Order instellingen">
                    <!-- <b-button @click="reset_foreign_keys">Reset foreign Keys</b-button> -->
                    <OrderSettingsScreen></OrderSettingsScreen>
                </b-tab>

                <b-tab v-if="createAny('settings')" title="PDC Import instellingen">
                    <PdcSettingsScreen></PdcSettingsScreen>
                </b-tab>

                <b-tab v-if="createAny('settings')" title="Signalerings instellingen">
                    <SignalingSettingsScreen></SignalingSettingsScreen>
                </b-tab>
            </b-tabs>
        </div>

        <div class="popup-footer">
            <div @click="$emit('close')" class="btn btn-secondary">Sluiten</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import PersonalSettingsScreen from './settings-screens/PersonalSettings'
import OrderSettingsScreen from './settings-screens/OrderSettings'
import PdcSettingsScreen from './settings-screens/PdcSettings'
import SignalingSettingsScreen from './settings-screens/SignalingSettings'

export default {
    name: 'settings-modal',
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters([
            'createAny',
            'readAny',
            'updateAny',
            'deleteAny',
        ]),
        ...mapState([
            'users',
            'workplaces',
            'user_planning',
            'workplace_comments',
            'settings'
        ])
    },
    methods:{
        ...mapActions([
            'save_setting',
            'reset_foreign_keys'
        ]),
    },
    components: { PersonalSettingsScreen, OrderSettingsScreen, PdcSettingsScreen, SignalingSettingsScreen }
}
</script>