<template>
	<div class="popup">

        <div class="popup-header">
            {{ workplace_by_id(workplace_id).name }}
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <b-table small striped hover :items="order_planning_by_workplace_id(workplace_id)" :fields="fields" primary-key="planning_id" id="orderrows" :tbody-tr-class="'temp-workplace-orderrow'" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :tbody-tr-attr="row_attributes">

                <template v-slot:cell()="row">
                    {{ row.value }}
                </template>

                <template v-slot:cell(workplace_id)="row">
                    {{ row.value ? workplace_by_id(row.value).name : '' }}
                </template>

                <template v-slot:cell(manager)="row">
                    {{ row.value ? user_by_id(row.value).firstname : '' }}
                </template>

                <template v-slot:cell(priority)="row">
                    {{ row.value | yesno }}
                </template>
                <template v-slot:cell(new)="row">
                    {{ row.value | yesno }}
                </template>
                <template v-slot:cell(changed)="row">
                    {{ row.value | yesno }}
                </template>
                <template v-slot:cell(outsourcing)="row">
                    {{ row.value | yesno }}
                </template>

                <template v-slot:cell(actions)="row">
                    <div class="btn-group ml-auto" role="group" aria-label="rowtools">
                        <div style="width: 30px" @click="show_order_details(row.item)" class="btn btn-primary btn-sm"><font-awesome-icon icon="info" /></div>
                    </div>
                </template>
                
            </b-table>
        </div>

        <div class="popup-footer">
            <div @click="$emit('close')" class="btn btn-secondary">Sluiten</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import jQuery from 'jquery';
import axios from 'axios';
import DragDropTarget from '../DragMixin'

import order_details_modal from './OrderDetailsModal'

export default {
    name: 'workplace-orders-modal',
    mixins: [DragDropTarget],
    data() {
        return {
            sortBy: 'ordernumber',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },
    props: {
        workplace_id: Number
    },
    computed: {
        ...mapGetters([
            'createAny',
            'readAny',
            'updateAny',
            'deleteAny',
            'order_planning_by_workplace_id',
            'workplace_by_id'
        ]),
        ...mapState([
            'settings',
            'importing',
            'unscheduled_orders'
        ]),
        fields() {
            return this.settings.temp_orders_columns.concat([{"key":"actions","label":"","sortable":0}])
        }
    },
    watch: {
        unscheduled_orders() {
            this.enable_row_dragging()
        }
    },
    mounted() {
        this.enable_row_dragging()
    },
    methods:{
        show_order_details(orderrow) {
            axios.get('/api/order/' + orderrow.order_id).then((response) => {
                // this.$modal.hide('order-details-modal')
				this.$modal.show(order_details_modal, {
					order: response.data,
					row: orderrow.orderrow_id
				}, {
                    name: 'order-details-modal',
					width: '1280px',
                    height: '800px'
				})
			})
        },
        ...mapActions([
            'begin_drag_item',
            'end_drag_item'
        ]),

        row_attributes(item, type) {
            if (!item || type !== 'row') return
            return { 'draggable': 'true' }
        },
        on_start_drag_row(event) {
            var row_id = jQuery(event.target).attr("data-pk")
            var orderrow = this.order_planning_by_workplace_id(this.workplace_id).find((item) => {
                return item.planning_id == row_id
            });
            if (orderrow)
                this.begin_drag_item({type: 'orderrow', id: orderrow.orderrow_id, date: null, id2: orderrow.planning_id })
        },
        on_stop_drag_row() {
            this.end_drag_item()
        },
        enable_row_dragging() {
            this.$nextTick(function () {
                jQuery('.temp-workplace-orderrow').unbind('dragstart', this.on_start_drag_row);
                jQuery('.temp-workplace-orderrow').unbind('dragend', this.on_stop_drag_row);

                jQuery('.temp-workplace-orderrow').on('dragstart', this.on_start_drag_row);
                jQuery('.temp-workplace-orderrow').on('dragend', this.on_stop_drag_row);
            })
        }
    },
    components: { }
}
</script>