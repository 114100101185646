<template>
  <header>
    <div class="server-status" :class="{'online': server_online, 'offline': !server_online}"></div>
    <div class="header-brand">Plantool</div>
    <b-spinner v-if="loading" small></b-spinner>

    <!-- TEXT -->
    <div v-if="is_logged_in && readAny('weekcomments')" class="header-message">
      <template v-for="(i, idx) in Math.ceil(num_display_columns / 6)">
        <div :key="idx + '-week-comment'" class="week-comment" :contenteditable="createAny('weekcomments')" @blur="on_content_changed($event, days[idx*6])" @change="on_content_changed($event, days[idx*6])" v-html="comment(days[idx*6]).text"></div>
      </template>
    </div>


    <div class="btn-group ml-auto mr-4" role="group" aria-label="Toolbar">
        <!-- <button type="button" class="btn btn-primary" style="width: 60px" @click="toggleOverview()">
            <font-awesome-icon :icon="weeksoverview ? 'calendar-day' : 'calendar-alt'" />
        </button> -->

        <button type="button" class="btn btn-primary" style="width: 60px" @click="toggle_employees()" :title="show_users ? 'Verberg gebruikers' : 'Toon gebruikers' ">
            <font-awesome-icon :icon="show_users ? 'user-slash' : 'user'" />
        </button>

        <button type="button" class="btn btn-primary" style="width: 60px" @click="toggle_sidebar()" :title="show_sidebar ? 'Verberg sidebar' : 'Toon sidebar' ">
            <font-awesome-icon v-bind:icon="show_sidebar ? 'eye-slash' : 'eye'" />
        </button>

        <!-- <button type="button" class="btn btn-primary" style="width: 60px" @click="toggle_workplaces()" :title="show_workplaces ? 'Verberg werkplekken' : 'Toon sidebar' ">
            <font-awesome-icon v-bind:icon="show_workplaces ? 'eye-slash' : 'eye'" />
        </button> -->
    </div>

  </header>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'title-bar',
	data() {
		return {
		}
	},
	computed: {
    ...mapGetters([
      'is_logged_in',
      'createAny',
			'readAny',
			'updateAny',
      'deleteAny',
      'days'
    ]),
		...mapState([
      'server_online',
      'week_comments',
      'num_display_columns',
      'loading',
      'show_users',
			'show_workplaces',
			'show_sidebar',
		])
  },
	methods: {
    toggle_employees() {
      this.$store.commit('show_users', !this.show_users)
    },
    toggle_sidebar() {
      this.$store.commit('show_sidebar', !this.show_sidebar)
    },
    on_content_changed(event, date) {
			this.$store.dispatch('set_week_comment', {date: new Date(date.getTime()), text: event.target.innerText })
    },
    comment(date) {
			if (this.week_comments[date.getTime()]) {
				return this.week_comments[date.getTime()]
			}
			return {
				date: date,
				text: ""
			}
		},
	},
	components: {},
	mounted() {}
}
</script>
