<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="workplace.id > 0">
                {{workplace.name}}
            </div>
            <div v-else>
                Nieuwe Werkplek
            </div>
            <div v-if="workplace.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <form class="form">
                
                <!-- dynamic ordermeta checkboxes -->
                <div class="form-row">
                    <div class="col offset-3">
                    <!-- loop through items -->
                    <div v-for="meta in workplace_checkbox_metas" v-bind:key="meta.key">
                        <div v-if="workplace[meta.toggle] || !meta.toggle" class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" v-bind:id="meta.key" v-model="workplace[meta.key]">
                            <label class="custom-control-label" v-bind:for="meta.key">{{ meta.label }}</label>
                        </div>
                    </div>
                    </div>
                </div>

                <!-- workplacename -->
                <div class="form-row">
                    <div class="col-3">
                        <label for="">Naam</label>
                    </div>
                    <div class="col">
                        <input type="text" class="form-control" v-model="workplace.name">
                    </div>
                </div>

                <!-- dynamic ordermetas -->
                <div v-for="meta in workplace_metas" v-bind:key="meta.key">
                    <div class="form-row" v-if="workplace[meta.toggle] || !meta.toggle">
                    <div class="col-3">
                        <label v-bind:for="meta.key">{{meta.label}}</label>
                    </div>
                    <div class="col" v-bind:class="{'col-4': meta.type == 'date', 'col-2': meta.type == 'number'}">
                        <input v-bind:type="meta.type" v-bind:placeholder="meta.label" v-bind:id="meta.key" class="form-control" v-model="workplace[meta.key]">
                    </div>
                    </div>
                </div>

            </form>
        </div>

        <div class="popup-footer">
            <div v-if="workplace.id == 0" class="btn btn-primary" @click="add_workplace(workplace)">Toevoegen</div>
            <div v-else @click="save_and_close" class="btn btn-secondary">Opslaan & Sluiten</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    name: 'workplace-details-modal',
    data() {
        return {
            new_workplace: {id: 0}
        }
    },
    props: {
        workplace_id: Number
    },
    computed: {
        workplace() {
            return this.workplace_id > 0 ? this.workplace_by_id(this.workplace_id) : this.new_workplace
        },
        ...mapGetters([
            'workplace_checkbox_metas',
            'workplace_metas',
            'workplace_by_id'
        ]),
        ...mapState([
            'workplaces',
            'settings'
        ])
    },
    methods: {
        save_and_close() {
            this.save_workplace(this.workplace.id)
            this.$emit('close')
        },
        ...mapActions([
            'save_workplace',
            'add_workplace'
        ]),
    }
}
</script>