<template>
	<div class="popup">

        <div class="popup-header">
            <div v-if="user.id > 0">
                {{user.firstname}} {{user.middlename}} {{user.lastname}}
            </div>
            <div v-else>
                Nieuwe gebruiker
            </div>
            <div v-if="user.id == 0" class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="save_and_close">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <form class="form">
                
                <!-- dynamic ordermeta checkboxes -->
                <div class="form-row">
                    <div class="col offset-3">
                    <!-- loop through items -->
                    <div v-for="meta in user_checkbox_metas" v-bind:key="meta.key">
                        <div v-if="user[meta.toggle] || !meta.toggle" class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" v-bind:id="meta.key" v-model="user[meta.key]">
                            <label class="custom-control-label" v-bind:for="meta.key">{{ meta.label }}</label>
                        </div>
                    </div>
                    </div>
                </div>

                <!-- Username -->
                <div class="form-row">
                    <div class="col-3">
                        <label for="">Gebruikersnaam</label>
                    </div>
                    <div class="col">
                        <input type="text" class="form-control" v-model="user.username">
                    </div>
                </div>
                
                <!-- Password -->
                <div class="form-row">
                    <div class="col-3">
                        <label for="">Wachtwoord</label>
                    </div>
                    <div class="col">
                        <input type="password" class="form-control" v-model="user.password" placeholder="">
                    </div>
                </div>

                <!-- Role -->
                <div class="form-row">
                    <div class="col-3">
                        <label for="">Rol</label>
                    </div>
                    <div class="col">
                        <select v-model="user.role" class="form-control">
                            <option value="user">Gebruiker</option>
                            <option value="planner">Planner</option>
                            <option value="hoofdplanner">Hoofdplanner</option>
                            <option value="admin">Admin</option>
                        </select>
                    </div>
                </div>

                <!-- dynamic ordermetas -->
                <div v-for="meta in user_metas" v-bind:key="meta.key">
                    <div class="form-row" v-if="user[meta.toggle] || !meta.toggle">
                    <div class="col-3">
                        <label v-bind:for="meta.key">{{meta.label}}</label>
                    </div>
                    <div class="col" v-bind:class="{'col-4': meta.type == 'date', 'col-2': meta.type == 'number'}">
                        <input v-bind:type="meta.type" v-bind:placeholder="meta.label" v-bind:id="meta.key" class="form-control" v-model="user[meta.key]" @change="darken_color(meta)">
                    </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-3">
                        <label for="font">Lettertype</label>
                    </div>
                    <div class="col-3">
                        <select id="font" v-model="user.font" class="form-control">
                            <option v-bind:value="f" v-for="f in fonts" v-bind:key="f">{{f}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-3">
                        <label for="fontweight">Letterdikte</label>
                    </div>
                    <div class="col-3">
                        <select id="fontweight" v-model="user.font_weight" class="form-control">
                            <option v-bind:value="'fw_'+i+'00'" v-for="i in 9" v-bind:key="i">{{i}}</option>
                        </select>
                    </div>
                </div>

                <!-- Herhaalde vrije dagen -->
                <div class="row">
                    <div class="col-12">
                        <h4>Herhaalde vrije uren</h4>
                    </div>
                </div>
                <div>
                    <div v-for="(blocked_hour, row_idx) in blocked_hours" v-bind:key="'blocked-hour-'+row_idx">
                        <div class="form-row">
                            <div class="col-3">
                                <div class="day" style="display: flex; padding-top: 8px;">
                                    <!-- HOURS -->
                                    <div v-for="(hour_value, hour_index) in blocked_hour.mask" 
                                        :key="'user-'+user_id +'-hour-'+hour_index" 
                                        :style="user_background_style(user_id)"
                                        :title="user_by_id(user_id).firstname"
                                        :class="{'hour-empty': hour_value > hour_status_free, 'hour-free': hour_value == hour_status_free, 'hour-blocked': hour_value == hour_status_blocked}" class="hour"
                                        @click="set_hour_mask(row_idx, hour_index)"
                                    />
                                </div>
                            </div>
                            <div class="col-3">
                                <input type="date" class="form-control" v-model="blocked_hour.start" @change="update_blocked_hours">
                            </div>
                            <div class="col-3">
                                <input type="date" class="form-control" v-model="blocked_hour.end" @change="update_blocked_hours">
                            </div>
                            <div class="col-2">
                                <select name="interval" class="form-control" v-model="blocked_hour.interval" @change="update_blocked_hours">
                                    <option :value="1">Dagelijks</option>
                                    <option :value="7">Wekelijks</option>
                                    <option :value="14">2 weken</option>
                                    <option :value="28">4 weken</option>
                                </select>
                            </div>
                            <div class="col-1">
                                <div style="width: 30px" @click="delete_hour_mask(row_idx)" class="btn btn-primary btn-sm"><font-awesome-icon icon="trash" /></div>
                            </div>
                        </div>
                    </div>
                    <div @click="add_hour_mask" class="btn btn-primary float-right">Toevoegen</div>
                </div>

            </form>
        </div>

        <div class="popup-footer">
            <div v-if="user.id == 0" class="btn btn-primary" @click="add_user(user); $emit('close')">Toevoegen</div>
            <div v-else @click="save_and_close" class="btn btn-primary">Opslaan & Sluiten</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Vue from 'vue'

export default {
    name: 'user-modal',
    data() {
        return {
            blocked_hours: [],
            new_user: {role: 'user', id: 0, color1: '#ccaaaa', color2: '#cc1111', font: 'source_sans_pro', font_weight: 'fw_600' }
        }
    },
    props: {
        user_id: Number
    },
    mounted() {
        this.blocked_hours = this.user.blocked_hours ? JSON.parse(this.user.blocked_hours) : []
    },
    // watch: {
    //     blocked_hours: {
    //         deep: true,
    //         handler() {
                
    //         }
    //     }
    // },
    computed: {
        user() {
            return this.user_id > 0 ? this.user_by_id(this.user_id) : this.new_user
        },
        ...mapGetters([
            'user_checkbox_metas',
            'user_metas',
            'user_by_id',
            'user_background_style',

            'hour_status_unknown',
			'hour_status_available',
			'hour_status_blocked',
            'hour_status_free',
            'default_planning'
        ]),
        ...mapState([
            'users',
            'workplaces',
            'user_planning',
            'workplace_comments',
            'settings',
            'current_user',
            'fonts'
        ])
    },
    methods: {
        delete_hour_mask(row) {
            this.blocked_hours.splice(row, 1)
            this.update_blocked_hours()
        },
        set_hour_mask(row, hour) {
            Vue.set(this.blocked_hours[row].mask, hour, this.blocked_hours[row].mask[hour] == this.hour_status_blocked ? this.hour_status_available : this.hour_status_blocked)
            this.update_blocked_hours()
        },
        add_hour_mask() {
            this.blocked_hours.push({'mask': JSON.parse(JSON.stringify(this.default_planning)), 'start': undefined, 'end': undefined, 'interval': 1})
            this.update_blocked_hours()
        },
        update_blocked_hours() {
            Vue.set(this.user, 'blocked_hours', JSON.stringify(this.blocked_hours))
        },
        save_and_close() {
            this.save_user(this.user.id)
            this.$emit('close')
        },
        ...mapActions([
            'save_user',
            'add_user'
        ]),


        addLight(color, amount){
            let cc = parseInt(color,16) + amount;
            let c = (cc > 255) ? 255 : (cc);
            c = (c.toString(16).length > 1 ) ? c.toString(16) : `0${c.toString(16)}`;
            return c;
        },
        lighten(color, amount) {
            color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
            amount = parseInt((255*amount)/100);
            return color = `#${this.addLight(color.substring(0,2), amount)}${this.addLight(color.substring(2,4), amount)}${this.addLight(color.substring(4,6), amount)}`;
        },
        subtractLight(color, amount){
            let cc = parseInt(color,16) - amount;
            let c = (cc < 0) ? 0 : (cc);
            c = (c.toString(16).length > 1 ) ? c.toString(16) : `0${c.toString(16)}`;
            return c;
        },
        darken(color, amount) {
            color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
            amount = parseInt((255*amount)/100);
            return color = `#${this.subtractLight(color.substring(0,2), amount)}${this.subtractLight(color.substring(2,4), amount)}${this.subtractLight(color.substring(4,6), amount)}`;
        },
        darken_color(meta) {
            if (meta.key == 'color1') Vue.set(this.user, 'color2', this.darken(this.user[meta.key], 25))
        }
    }
}
</script>