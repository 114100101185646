<template>
	<div class="row">
		<!-- WORKPLACE NAME -->
		<div class="first-col"
			@dragenter="on_enter_drag($event, null, null, 'workplace-row-'+workplace_id)"
			@dragover="on_enter_drag($event, null, null, 'workplace-row-'+workplace_id)"
			@click="show_workplace_overview(workplace_id)"
			>
			<div
				class="overlay fullweek"
				:class="{ 'dragover': drag_over_element == 'workplace-row-'+workplace_id }" 
				@dragover="on_over_drag($event, null, null)"
				@dragleave="on_leave_drag()"
				@drop="on_drop_item('workplace-week', workplace_id, datesList[0])"
			/>
			{{ workplace_by_id(workplace_id).name }}
			<!-- <div v-if="$store.getters.createAny('userholiday')" class="vacant-hours" title="Resterende uren">{{ workplace_by_id(workplace_id).vacant_hours || 0 }}</div> -->
			<div class="vacant-hours" title="Resterende uren">{{ label }}</div>
		</div>
		
		<!-- DAYS -->
		<div v-for="(date, day_index) in datesList" 
			:key="get_workplace_day_key(workplace_id, date)" 
			class="day"
			:class="'day-'+date.getDay()"
			@dragenter="on_enter_drag($event, null, date, get_workplace_day_key(workplace_id, date))"
			@dragover="on_enter_drag($event, null, date, get_workplace_day_key(workplace_id, date))"
			>
			
			<div
				class="overlay"
				:class="{ 'dragover': drag_over_element == get_workplace_day_key(workplace_id, date)  }" 
				@dragover="on_over_drag($event, null, date)"
				@dragleave="on_leave_drag()"
				@drop="on_drop_item('workplace', workplace_id, date)"
			/>

			<!-- EMPLOYEES -->
			<template v-for="(user_planning, idx) in planning(date)">
				<div v-if="user_planning.indexOf(workplace_id) > -1 && (show_archived || (!show_archived && user_by_id(idx).archived != 1))" :key="'user-'+idx+'-workplace-'+workplace_id+'-planning-'+day_index" class="hours">
					<div v-for="(hour_value, hour_index) in user_planning" 
						:key="'user-'+idx+'-workplace-'+workplace_id+'-planning-'+day_index+'-'+hour_index" 
						:style="user_background_style(idx)"
						:title="user_by_id(idx).firstname"
						:class="{'hour-empty': hour_value != workplace_id}" class="hour"
						:draggable="is_hour_draggable(hour_value)" 

						@dragstart="begin_drag_item({type: 'hour', id: idx, date: date, id2:hour_index})"
						@dragend="end_drag_item()"
						@dblclick="plan_user_hour({user_id: idx, date: new Date(date), hour:hour_index, type: -1})"
					/>
				</div>
			</template>

			<!-- ORDERS -->
			<div v-for="workplace_order in order_planning_by_workplace_id_and_date(workplace_id, date)" 
				:key="'order-'+workplace_order.planning_id+'workplace'+workplace_id+'-planning-'+day_index" 
				class="order" 
				:class="planned_order_classes(workplace_order)" 
				:style="planned_order_padding(workplace_order)"
				:draggable="is_order_draggable(workplace_order.order_id)" 

				@contextmenu="$event.preventDefault(); $store.commit('marked_order_id', workplace_order.order_id)"

				@dragstart="begin_drag_item({type: 'orderrow', id: workplace_order.orderrow_id, date: null, id2: workplace_order.planning_id })"
				@dragend="end_drag_item()"
				>

				<div class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" :id="'order-'+workplace_order.planning_id+'-completed'" v-model="workplace_order.completed" @change="set_orderrow_planning_complete({ planning_id: workplace_order.planning_id } )">
					<label class="custom-control-label" :for="'order-'+workplace_order.planning_id+'-completed'"></label>
				</div>

				<span class="order-number" @click="show_order_details(workplace_order)" :class="font_classes" :title="formatted_order_number(workplace_order)" v-html="formatted_order_number(workplace_order)"></span>
				<span class="order-time" :contenteditable="createAny('orderplanning')" @blur="order_time_changed($event, workplace_order.planning_id)" @keydown.enter="$event.target.blur()" :class="font_classes">{{ workplace_order.planning_duration }}</span>

				<div class="order-status" :title="workplace_order.new == 1 ? 'Nieuw' : workplace_order.changed == 1 ? 'Gewijzigd' : ''" :class="{new: workplace_order.new, changed: workplace_order.changed, concept: workplace_order.concept}"></div>
				<div v-if="workplace_order.remarks != ''" class="order-comment"></div>
			</div>

			<!-- TEXT -->
			<div v-if="readAny('workplacecomments')" class="workplace-day-comment">
				<div :contenteditable="createAny('workplacecomments')" class="field-value" :class="font_classes" @blur="on_content_changed($event, workplace_id, date)" @change="on_content_changed($event, workplace_id, date)" v-html="comment(date).text"></div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import DragDropTarget from './DragMixin'

import order_details_modal from './modals/OrderDetailsModal'
import workplace_overview_modal from './modals/WorkplaceOverviewModal'
import message_modal from './modals/MessageModal'

export default {
	name: 'workplace',
	mixins: [DragDropTarget],
	data() {
		return {
		}
	},
	props: {
		workplace_id: Number,
		datesList: Array,
		label: String,
		show_archived: Boolean
	},
	computed: {
		...mapGetters([
			'workplace_by_id',
			'user_by_id',
			'user_background_style',
			'order_planning_by_workplace_id_and_date',

			'is_order_draggable',
			'is_hour_draggable',

			'hour_status_unknown',
			'hour_status_available',
			'hour_status_blocked',
			'hour_status_free',

			'formatted_order_number',
			'planned_order_classes',
			'planned_order_padding',
			'font_classes',

			'createAny',
			'readAny',
			'updateAny',
			'deleteAny',

			'order_planning_by_planning_id'
		]),
		...mapState([
			'user_planning',
			'workplace_comments'
		])
	},
	updated() {
		//check if there is a order 'marked' from search, if so, scroll this workplace into view
		const element = this.$el.querySelector(".marked")
		if (element) {
			this.$el.scrollIntoView()
			this.$el.focus()
		}
	},
	methods: {
		...mapActions([
			'plan_user_hour',
			'begin_drag_item',
			'end_drag_item',
			'set_orderrow_planning_complete',
			'plan_orderrow'
		]),
		show_workplace_overview(workplace_id) {
			this.$modal.hide('workplace-overview-modal')
			this.$modal.show(workplace_overview_modal, {
				workplace_id: workplace_id
			}, {
				name: 'workplace-overview-modal',
					height: 'auto',
					width: '1036px'
				})
		},
		get_workplace_day_key(workplace_id, date) {
			return 'workplace-'+workplace_id+'-day-'+date.getTime()
		},
		planning(date) {
			if (this.user_planning[date.getTime()]) {
				return this.user_planning[date.getTime()]
			}
			return []
		},
		comment(date) {
			if (this.workplace_comments[date.getTime()] && this.workplace_comments[date.getTime()][this.workplace_id]) {
				return this.workplace_comments[date.getTime()][this.workplace_id]
			}
			return {
				workplace_id: this.workplace_id,
				date: date,
				text: ""
			}
		},
		show_order_details(planning_order) {
			axios.get('/api/order/' + planning_order.order_id).then((response) => {
				this.$store.dispatch('clear_marked_orders')
				// this.$modal.hide('order-details-modal')
				this.$modal.show(order_details_modal, {
					order: response.data,
					row: planning_order.orderrow_id
				}, {
					name: 'order-details-modal',
					width: '1280px',
					height: '800px'
				})
			})
		},

		on_content_changed(event, workplace_id, date) {
			if (this.comment(date).text != event.target.innerHTML)
				this.$store.dispatch('set_workplace_comment', {workplace_id: workplace_id, date: date, text: event.target.innerHTML })
		},
		order_time_changed(event, planning_id) {
			var new_duration = parseFloat(event.target.innerText)
			var orderrow = this.order_planning_by_planning_id(planning_id)
			if (!isNaN(new_duration)) {
				var max_duration = parseFloat(orderrow.total_remaining) + parseFloat(orderrow.planning_duration)
				
				if (new_duration > max_duration) {
					//show error message
					this.$modal.show(message_modal, {
						title: 'Ongeldige tijd',
						message: 'Er is maximaal ' + max_duration + ' uur beschikbaar'
					})

					//revert to original time
					event.target.innerHTML = orderrow.planning_duration
				} else if (new_duration <= 0) {
					//show error message
					this.$modal.show(message_modal, {
						title: 'Ongeldige tijd',
						message: 'Voor een tijd hoger dan 0 in'
					})

					//revert to original time
					event.target.innerHTML = orderrow.planning_duration
				} else {
					Vue.set(orderrow, 'planning_duration', new_duration)
					this.$store.dispatch('plan_orderrow', { workplace_id: orderrow.planning_workplace_id, orderrow_id: orderrow.orderrow_id, date: new Date(orderrow.date), planning_id: orderrow.planning_id })
				}
			} else {
				//show error message
					this.$modal.show(message_modal, {
						title: 'Ongeldige tijd',
						message: 'Voer een geldig getal in'
					})

				//revert to original time
				event.target.innerHTML = orderrow.planning_duration
			}
		}
	},
}
</script>