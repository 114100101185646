<template>
	<div class="popup">

        <div class="popup-header">
            Orderregel verwijderen
            <div class="popup-close" @click="$emit('close')">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body">
            <div class="form-row">
                <div class="col">
                    <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" value="1" id="row" v-model="deleteType">
                        <label class="custom-control-label" for="row">Verwijder orderregel</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" value="2" id="order" v-model="deleteType">
                        <label class="custom-control-label" for="order">Verwijder gehele order</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup-footer">
            <div @click="$emit('close')" class="btn btn-secondary">Sluiten</div>
            <div v-if="deleteType == 1" @click="delete_orderrow(orderrow_id); $emit('close')" class="btn btn-primary">Verwijder</div>
            <div v-if="deleteType == 2" @click="delete_order(orderrow_by_orderrow_id(orderrow_id).order_id); $emit('close')" class="btn btn-primary">Verwijder</div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'delete-order-modal',
    data() {
        return {
            deleteType: 1,
        }
    },
    props: {
        orderrow_id: Number,
    },
    computed: {
        ...mapGetters([
            'orderrow_by_orderrow_id'
        ])
    },
    methods: {
        ...mapActions([
            'delete_orderrow',
			'delete_order',
        ]),
    }
}
</script>