<template>
	<div id="app" class="wrapper">
		<GlobalEvents @keyup.escape="clear_marked_orders" />
		<TitleBar />

		<!-- LOGIN -->
		<div class="container" v-if="!is_logged_in">
			<div class="row justify-content-center">
				<Login />
			</div>
		</div>

		<main v-else class="home">
			<!-- PLANNING -->
			<div class="content">
				<div class="calendar">

					<!-- DAYS -->
					<div class="days">
						<div class="row">
								<div
									class="first-col currentweek"
									@dragenter="on_enter_drag($event, null, date, 'date-week')"
									@dragover="on_enter_drag($event, null, date, 'date-week')"
									>
									{{ current_week }}
									<div
										class="overlay fullweek"
										:class="{ 'dragover': drag_over_element == 'date-week' }" 
										@dragover="on_over_drag($event, null, null)"
										@dragleave="on_leave_drag()"
										@drop="on_drop_item('week', null, null)"
									/>
								</div>

								<div v-for="(date, index) in days" 
									:key="'date-column-'+index"
									class="day"
									@dragenter="on_enter_drag($event, null, date, 'date-column-'+index)"
									@dragover="on_enter_drag($event, null, date, 'date-column-'+index)"
									@dblclick="on_dblclick(date)"
									>
									<b class="mr-1">{{ date_day(date) }}</b>&nbsp;{{ date.getDate() }}&nbsp;{{ date_month(date) }}
									<div
										class="overlay"
										:class="{ 'dragover': drag_over_element == 'date-column-'+index }" 
										@dragover="on_over_drag($event, null, date)"
										@dragleave="on_leave_drag()"
										@drop="on_drop_item('day', null, date)"
									/>

								</div>
						</div>
					</div>

					<!-- EMPLOYEES -->
					<div v-if="show_users" class="employees">

						<!-- EMPLOYEE ROW -->       
							<Employee v-for="user in visible_users" :key="'user-row-'+user.id" :user_id="user.id" />
					</div>

					<!-- WORKPLACES -->
					<simplebar class="workplaces-scrollbar" data-simplebar-auto-hide="false">
						<div v-if="show_workplaces" class="workplaces">
							<!-- WORKPLACE ROW -->
							<Workplace v-for="workplace in visible_workplaces" :key="'workplace-row-'+workplace.id" :workplace_id="workplace.id" :datesList="days" :label="workplace_label(workplace.id)" />
						</div>
					</simplebar>

				</div>

				<!-- WEEK BUTTONS -->
				<div class="weeks">
					<div :key="'select-week-btn-'+prev_week" class="week prevnext" @click="set_date({date: prev_week})"><font-awesome-icon icon="angle-double-left" /></div>
					<div v-for="week in weeks" :key="'select-week-btn-'+week.getTime()" class="week" v-bind:class="{ current: week.getWeekNumber() == new Date().getWeekNumber() && week.getFullYear() == new Date().getFullYear(), active: week.getWeekNumber() == date.getWeekNumber() && week.getFullYear() == date.getFullYear() }" @click="set_date({date: week})">{{week.getWeekNumber()}}</div>
					<div :key="'select-week-btn-'+next_week" class="week prevnext" @click="set_date({date: next_week})"><font-awesome-icon icon="angle-double-right" /></div>
				</div>

			</div>

			<Sidebar v-if="show_sidebar" />
		</main>
	</div>
</template>

<style>
:root {
	--hour-size: 18px;
	--calendar-font-size: 12px;
}
</style>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import DragDropTarget from './components/DragMixin'

import Sidebar from './components/Sidebar'

import Login from './components/Login'
import TitleBar from './components/TitleBar'

import Employee from './components/Employee'

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
	name: 'app',
	data() {
		return {
			resize_timeout: null,
		}
	},
	mixins: [DragDropTarget],
	computed: {
		...mapGetters([
			'is_logged_in',

			'visible_users',
			'visible_workplaces',
			
			'days',
			'weeks',
			'prev_week',
			'next_week',
			'current_week',
			'date_day',
			'date_month',
			'workplace_by_id',

			'createAny'
		]),
		...mapState([
			'loading',
			'date',
			'settings',
			'show_users',
			'show_workplaces',
			'show_sidebar',
		])
	},
	mounted: function () {
		window.addEventListener('resize', this.handleResize)
		this.setNumColumns()
		this.initialize()
	},

	
	beforeDestroy: function () {
		window.removeEventListener('resize', this.handleResize)
	},
	methods: {
		...mapActions([
			'initialize',
			'set_date',
			'clear_marked_orders'
		]),

		handleResize () {
			clearTimeout(this.resize_timeout)
			this.resize_timeout = setTimeout(this.setNumColumns, 100);
		},
		setNumColumns() {
			this.$store.commit('num_display_columns', Math.floor((window.innerWidth - 124) / 144) + 1 )
		},
		on_dblclick(date) {
			this.visible_users.forEach(user => {
				this.$store.dispatch('plan_user_day', {user_id: user.id, date: date, type: this.hour_status_free })
			});
		},
		workplace_label(workplace_id) {
			var labels = []
			if (this.createAny('userholiday')) {
				if (this.settings.show_activeweeks_remaining) {
					labels.push(this.workplace_by_id(workplace_id).future_vacant_hours.toString() || '0')
				}
				if (this.settings.show_total_remaining) {
					labels.push(this.workplace_by_id(workplace_id).vacant_hours.toString() || '0')
				}
			}
			// $store.getters.createAny('userholiday') ? ((workplace_by_id(workplace.id).vacant_hours.toString() || '0') + ' ' + (workplace_by_id(workplace.id).future_vacant_hours.toString() || '0')) : ''

			return labels.join(' / ')
		},
	},
	components: { simplebar, Login, TitleBar, Sidebar, Employee }
}
</script>