import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

const AccessControl = require('accesscontrol')

Vue.use(Vuex)

const month_strings = ["jan", "feb", "maa", "apr", "mei", "jun", "jul", "aug", "sept", "okt", "nov", "dec"]
const day_strings = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"]
const hour_status_unknown = -1    // nog nooit iets gedaan met dit uur (== beschikbaar)
const hour_status_available = -2  // medewerker is beschikbaar
const hour_status_blocked = -3    // medewerker is niet aanwezig op basis van masker / herhaalde vrije uren
const hour_status_free = 0        // medewerker is niet aanwezig
const default_planning = [hour_status_unknown, hour_status_unknown, hour_status_unknown, hour_status_unknown, hour_status_unknown, hour_status_unknown, hour_status_unknown, hour_status_unknown]

export default new Vuex.Store({
  state: {
    loading: false,

    //API ERRORS
    api_error: null,

    fonts: [
      "nobile",
      "exo",
      "exo2",
      "istok_web",
      "ubuntu",
      "open_sans",
      "source_sans_pro",
      "roboto",
      "lato",
      "pt_sans"
    ],

    //SERVER SETTINGS
    server_online: false,

    //LAYOUT OPTIONS
    show_users: true,
    show_workplaces: true,
    show_sidebar: true,

    //ROLES
    roles: null,

    //DRAGGING DATA
    drag_data: null,

    //DATA
    date: new Date(),
    num_columns: 18,
    num_display_columns: 18,

    //USER DATA
    current_user: null,
    users: [],
    user_planning: [],

    //WORKPLACES
    workplaces: [],

    //ORDERS
    unscheduled_orders: [],
    scheduled_orders: [],
    importing: false,

    //SEARCH / MARKING
    searchvalue: "",
    searchstatus: [0, 1],
    searchdate: "",
    searchmanager: 0,
    searchresults: [],
    searching: false,
    marked_order_id: 0,
    marked_orderrow_id: 0,
    marked_orderplanning_id: 0,

    //COMMENTS
    workplace_comments: [],
    week_comments: [],

    //SETTINGS
    settings: {},
  },
  getters: {
    //DATE GETTERS
    //gets the current week string
    current_week: (state) => {
      return state.date.getFullYear() + " / " + state.date.getWeekNumber()
    },
    //gets an array of dates (columns) starting from the current selected date
    days:(state) => {
      var cols = []
      var d = new Date(state.date)
      d.setHours(0,0,0,0)
      for (var i = 0; i < state.num_display_columns; i++) {
        if (d.getDay() == 0) {
          d.setDate(d.getDate() + 1)
        }

        cols.push(new Date(d.getTime()))

        d.setDate(d.getDate() + 1)
        d = new Date(d.getTime())
      }
      return cols
    },
    //return an array of dates, increasing with 7 days (weeks)
    weeks:(state) => {
      var d = new Date()
      d.setHours(0, 0, 0, 0)
      var o = d.getDay() - 1
      var newDate = d.getDate() - o
      d.setDate(newDate)

      var startDate = d
      var i = 0
      for (i = 0; i < (7 * 9); i++) {
          startDate.setDate(startDate.getDate() - 1)
      }
      var weeks = []
      var numWeeks = Math.max(10, (state.num_display_columns * 4) - 4)
      for (i = 0; i < numWeeks; i++) {
          weeks.push(new Date(startDate.getTime()))

          for (var j = 0; j < 7; j++) {
              startDate.setDate(startDate.getDate() + 1)
          }
      }

      return weeks
    },
    prev_week:(state) => {
      var d = new Date(state.date)
      d.setDate(d.getDate() - 7)
      return d;
    },
    next_week:(state) => {
      var d = new Date(state.date)
      d.setDate(d.getDate() + 7)
      return d;
    },
    date_day:() => (date) => {
      return day_strings[date.getDay()]
    },
    date_month:() => (date) => {
      return month_strings[date.getMonth()]
    },

    //USER GETTERS
    is_logged_in: (state) => {
      return state.current_user != null;
    },
    user_background_style: (state, getters) => (user_id) => {
      var user = getters.user_by_id(user_id)
      if (user) {
        return { 'background-image': 'linear-gradient(45deg, '+user.color1+', '+user.color2+')' }
      }
      return {}
    },
    visible_users: (state, getters) => {
      if (state.current_user != undefined && getters.user_by_id(state.current_user.id) != undefined && getters.user_by_id(state.current_user.id).visible_users != undefined)
        return state.users.filter(user => JSON.parse(getters.user_by_id(state.current_user.id).visible_users).indexOf(user.id) > -1 && user.showplanning && user.archived != 1)
      else
        return state.users.filter(user => user.showplanning && user.archived != 1)
    },
    user_index_by_user_id: (state) => {
      return state.users.reduce((map, item, index) => {
        map[item.id] = index
        return map
      }, {})
    },
    user_by_id: (state, getters) => (id) => {
      return state.users[getters.user_index_by_user_id[id]]
    },
    role:(state) => {
      return state.current_user.role
    },
    createAny:(state, getters) => (resource) => {
      if (state.roles != null) {
        return state.roles.can(getters.role).createAny(resource).granted
      }
      return false;
    },
    readAny:(state, getters) => (resource) => {
      if (state.roles != null) {
        return state.roles.can(getters.role).readAny(resource).granted
      }
      return false;
    },
    updateAny:(state, getters) => (resource) => {
      if (state.roles != null) {
        return state.roles.can(getters.role).updateAny(resource).granted
      }
      return false;
    },
    deleteAny:(state, getters) => (resource) => {
      if (state.roles != null) {
        return state.roles.can(getters.role).deleteAny(resource).granted
      }
      return false;
    },

    is_hour_draggable:(state, getters) => (current_hour_state) => {
      return getters.createAny('userplanning') && current_hour_state != hour_status_blocked && current_hour_state != hour_status_free
    },
    is_order_draggable:(state, getters) => (order_id) => {
      if (order_id > 0 && getters.createAny('orderplanning'))
        return true 
      
      return false;
    },
    is_user_draggable:(state, getters) => (user_id) => {
      if (user_id > 0 && getters.createAny('userplanning'))
        return true
      return false
    },

    user_planning_by_date:(state, getters) => (user_id, date) => {
      var planning = JSON.parse(JSON.stringify(default_planning))

      if (state.user_planning[date.getTime()] && state.user_planning[date.getTime()][user_id]) {
        planning = state.user_planning[date.getTime()][user_id]
      } else {
        var employee = getters.user_by_id(user_id)
        
        //apply user-mask
        if (Object.prototype.hasOwnProperty.call(employee, 'blocked_hours')) {
          var blocked_hours = JSON.parse(employee.blocked_hours)
          date.setHours(0,0,0,0)

          var tmpPlanning = JSON.parse(JSON.stringify(default_planning))
          var changed = false

          blocked_hours.forEach(function(item) {
            var start_date = item.start ? new Date(item.start) : new Date(Date.now() - (1000 * 60 * 60 * 24 * 365 * 10))
            var end_date = item.end ? new Date(item.end) : new Date(Date.now() + (1000 * 60 * 60 * 24 * 365 * 10))

            if (date >= start_date && date <= end_date) {
              const diffTime = Math.abs(date.getTime() - start_date.getTime())
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
              if (parseInt(diffDays) % parseInt(item.interval) == 0)  {
                tmpPlanning = JSON.parse(JSON.stringify(item.mask))
                changed = true
              }
            }
          })

          if (changed) {
            planning = JSON.parse(JSON.stringify(tmpPlanning))
            changed = false
          }
        }
      }

      return planning;
    },

    //WORKPLACES GETTERS
    visible_workplaces: (state, getters) => {
      if (state.current_user != undefined && getters.user_by_id(state.current_user.id) != undefined && getters.user_by_id(state.current_user.id).visible_workplaces != undefined)
        return state.workplaces.filter(workplace => JSON.parse(getters.user_by_id(state.current_user.id).visible_workplaces).indexOf(workplace.id) > -1).filter(workplace => workplace.temp_storage != 1 && workplace.archived != 1)
      else
        return state.workplaces.filter(workplace => workplace.temp_storage != 1 && workplace.archived != 1)
    },
    temp_workplaces: (state) => {
      return state.workplaces.filter(workplace => workplace.temp_storage == 1)
    },
    workplace_index_by_workplace_id: (state) => {
      return state.workplaces.reduce((map, item, index) => {
        map[item.id] = index
        return map
      }, {})
    },
    workplace_by_id: (state, getters) => (id) => {
      return state.workplaces[getters.workplace_index_by_workplace_id[id]]
    },

    //ORDERS
    order_planning_index_by_planning_id: (state) => {
      return state.scheduled_orders.reduce((map, item, index) => {
        map[item.planning_id] = index
        return map
      }, {})
    },
    order_planning_by_planning_id: (state, getters) => (id) => {
      return state.scheduled_orders[getters.order_planning_index_by_planning_id[id]]
    },
    order_planning_index_by_workplace_id_and_date: (state) => {
      return state.scheduled_orders.reduce((map, item, index) => {
        const combined = item.planning_workplace_id+'-'+new Date(item.date).getTime();
        map[combined] = map[combined] || [];
        map[combined].push(index);
        return map
      }, {})
    },
    order_planning_by_workplace_id_and_date: (state, getters) => (workplace_id, date) => {
      const combined = workplace_id+'-'+new Date(date).getTime();
      return (getters.order_planning_index_by_workplace_id_and_date[combined] || []).map(index => state.scheduled_orders[index]).sort((a,b) => (a.planning_sort > b.planning_sort) ? 1 : ((b.planning_sort > a.planning_sort) ? -1 : 0));
    },
    order_planning_index_by_workplace_id: (state) => {
      return state.scheduled_orders.reduce((map, item, index) => {
        map[item.planning_workplace_id] = map[item.planning_workplace_id] || [];
        map[item.planning_workplace_id].push(index);
        return map
      }, {})
    },
    order_planning_by_workplace_id: (state, getters) => (workplace_id) => {
      return (getters.order_planning_index_by_workplace_id[workplace_id] || []).map(index => state.scheduled_orders[index]).sort((a,b) => (a.planning_sort > b.planning_sort) ? 1 : ((b.planning_sort > a.planning_sort) ? -1 : 0));
    },
    orderrow_index_by_orderrow_id: (state) => {
      return state.unscheduled_orders.reduce((map, item, index) => {
        map[item.orderrow_id] = index
        return map
      }, {})
    },
    orderrow_by_orderrow_id: (state, getters) => (orderrow_id) => {
      return state.unscheduled_orders[getters.orderrow_index_by_orderrow_id[orderrow_id]]
    },
    order_planning_index_by_order_id: (state) => {
      return state.scheduled_orders.reduce((map, item, index) => {
        map[item.order_id] = map[item.order_id] || [];
        map[item.order_id].push(index);
        return map
      }, {})
    },
    order_planning_by_order_id: (state, getters) => (order_id) => {
      return (getters.order_planning_index_by_order_id[order_id] || []).map(index => state.scheduled_orders[index]).sort((a,b) => (a.planning_sort > b.planning_sort) ? 1 : ((b.planning_sort > a.planning_sort) ? -1 : 0));
    },

    //ORDER NUMBER
    formatted_order_number: (state) => (planned_orderrow) => {
      var regex = /%.*?%/gi;
      var mask = state.settings.planned_order_mask;

      return mask.replace(regex, (match) => {
        var field = match.replace(/%/g, '');
        if (Object.prototype.hasOwnProperty.call(planned_orderrow, field))
          return planned_orderrow[field]
        return ''
      })
      // return planned_orderrow.ordernumber
    },
    planned_order_classes:(state, getters) => (planned_order) => {
      var status_class = getters.planned_order_class(planned_order, state.settings.order_statusses)
      var conditions_class = getters.planned_order_class(planned_order, state.settings.order_conditions)
      var classes = {}
      if (status_class != '') {
        classes[status_class] = 1
      }
      if (conditions_class != '') {
        classes[conditions_class] = 1
      }

      if (state.marked_order_id == planned_order.order_id || state.marked_orderrow_id == planned_order.orderrow_id || state.marked_orderplanning_id == planned_order.planning_id) {
          classes['marked'] = 1;
      }

      return classes
    },
    planned_order_class:(state) => (planned_order, statusses) => {
      var result = '';
      for (let status of statusses) {
        var field1 = Object.prototype.hasOwnProperty.call(planned_order, status.field1) ? planned_order[status.field1] : status.field1;
        var field2 = Object.prototype.hasOwnProperty.call(planned_order, status.field2) ? planned_order[status.field2] : status.field2;
        var compare = status.compare;

        if (Object.prototype.hasOwnProperty.call(status, 'fieldtype') && status.fieldtype == 'date') {
          if (field1 == null) continue;
          field1 = new Date(field1);
          field1 = new Date(field1.getFullYear(), field1.getMonth(), field1.getDate());
          if (field2 == null) continue;
          field2 = new Date(field2);
          field2 = new Date(field2.getFullYear(), field2.getMonth(), field2.getDate());
        }

        var meta = state.settings.orderrow_metas.filter(m => m.key == status.field1);
        var toggled = true;
        if (meta.length == 1 && meta[0].toggle && Object.prototype.hasOwnProperty.call(planned_order, meta[0].toggle)) {
          toggled = planned_order[meta[0].toggle] == 1 ? true : false;
        }

        if (toggled && !status.disabled) {
          switch (compare) {
            case '=':
              if (field1 == field2) {
                result = status.class;
              }
              break;
            case '!=':
              if (field1 != field2) {
                result = status.class;
              }
              break;
            case '<':
              if (field1 < field2) {
                result = status.class;
              }
              break;
            case '>':
              if (field1 > field2) {
                result = status.class;
              }
              break;
            case '<=':
              if (field1 <= field2) {
                result = status.class;
              }
              break;
            case '>=':
              if (field1 >= field2) {
                result = status.class;
              }
              break;
          }

          if (result != '') {
            break;
          }
        }
      }
      return result;
    },
    planned_order_padding:() => (planned_order) => {
      if (planned_order.new || planned_order.changed || planned_order.concept || planned_order.remarks != '') {
        return 'padding-right: 1.25rem';
      } else {
        return 'padding-right: .3rem';
      }
    },

    //LAYOUT
    font: (state, getters) => {
      var user = getters.user_by_id(state.current_user.id)
      if (user && user.font)
        return user.font
      return 'source_sans_pro'
    },
    font_weight: (state, getters) => {
      var user = getters.user_by_id(state.current_user.id)
      if (user && user.font_weight)
        return user.font_weight
      return 'fw_700'
    },
    font_classes: (state, getters) => {
      return getters.font + ' ' + getters.font_weight
    },

    //STATIC VARS
    month_strings: () => {
      return month_strings
    },
    day_strings: () => {
      return day_strings
    },
    hour_status_unknown: () => {
      return hour_status_unknown
    },
    hour_status_available: () => {
      return hour_status_available
    },
    hour_status_blocked: () => {
      return hour_status_blocked
    },
    hour_status_free: () => {
      return hour_status_free
    },
    default_planning:() => {
      return default_planning
    },

    //SETTINGS
    order_checkbox_metas: (state) => {
      return state.settings.order_metas.filter((item) => item.type == 'checkbox');
    },
    order_metas: (state) => {
      return state.settings.order_metas.filter((item) => item.type != 'checkbox');
    },

    order_row_checkbox_metas: (state) => {
      return state.settings.orderrow_metas.filter((item) => item.type == 'checkbox');
    },
    order_row_metas: (state) => {
      return state.settings.orderrow_metas.filter((item) => item.type != 'checkbox');
    },

    user_checkbox_metas: (state) => {
      return state.settings.user_metas.filter((item) => item.type == 'checkbox');
    },
    user_metas: (state) => {
      return state.settings.user_metas.filter((item) => item.type != 'checkbox');
    },

    workplace_checkbox_metas: (state) => {
      return state.settings.workplace_metas.filter((item) => item.type == 'checkbox');
    },
    workplace_metas: (state) => {
      return state.settings.workplace_metas.filter((item) => item.type != 'checkbox');
    },
  },
  mutations: {
    //DATE MUTATIONS
    date(state, date) {
      state.date = date
    },
    num_columns(state, num_columns) {
      state.num_columns = num_columns
    },
    num_display_columns(state, num_display_columns) {
      state.num_display_columns = num_display_columns
    },
    planning(state, planning) {
      state.user_planning = planning.user_planning
      state.workplace_comments = planning.workplace_comments
      state.scheduled_orders = planning.scheduled_orders
      state.week_comments = planning.week_comments

      state.date = planning.date
    },
    workplace_vacant_hours(state, vacant) {
      for (let w of state.workplaces) {
        let val = vacant.find(el => el.id == w.id)
        Vue.set(w, 'future_vacant_hours', val.future_vacant_hours)
      }
    },

    show_users(state, online) {
      state.show_users = online;
    },
    show_sidebar(state, online) {
      state.show_sidebar = online;
    },

    //SERVER MUTATIONS
    server_online(state, online) {
      state.server_online = online;
    },
    loading(state, loading) {
      state.loading = loading
    },
    api_error(state, error) {
      state.api_error = error
    },
    //DRAGGING
    drag_data(state, drag_data) {
      state.drag_data = drag_data
    },

    //USER MUTATIONS
    current_user(state, current_user) {
      state.current_user = current_user;
    },
    users(state, users) {
      state.users = users
    },
    user_planning(state, user_planning) {
      state.user_planning = user_planning
    },
    single_user_planning(state, planning) {
      if (state.user_planning[planning.date.getTime()] == undefined) Vue.set(state.user_planning, planning.date.getTime(), {})
      Vue.set(state.user_planning[planning.date.getTime()], planning.user_id, planning.planning)
    },
    
    //WORKPLACE MUTATIONS
    workplaces(state, workplaces) {
      state.workplaces = workplaces
    },
    workplace(state, workplace) {
      var index = state.workplaces.findIndex(function(item) {
        return (item.id == workplace.id);
      });
      var w = Object.assign(state.workplaces[index], workplace)
      Vue.set(state.workplaces, index, w);
    },

    //ORDERS
    unscheduled_orders(state, unscheduled_orders) {
      state.unscheduled_orders = unscheduled_orders
    },
    scheduled_orders(state, scheduled_orders) {
      state.scheduled_orders = scheduled_orders
    },
    importing(state, importing) {
      state.importing = importing
    },

    //COMMENT MUTATIONS
    workplace_comments(state, workplace_comments) {
      state.workplace_comments = workplace_comments
    },
    workplace_comment(state, comment) {
      if (!state.workplace_comments[comment.date.getTime()]) Vue.set(state.workplace_comments, comment.date.getTime(), [])
      Vue.set(state.workplace_comments[comment.date.getTime()], comment.workplace_id, comment)
    },

    week_comments(state, week_comments) {
      state.week_comments = week_comments
    },
    week_comment(state, comment) {
      Vue.set(state.week_comments, comment.date.getTime(), comment)
    },


    //SETTINGS
    settings(state, newSettings) {
      Object.keys(newSettings).forEach(function(key) {
        var value = newSettings[key];
        Vue.set(state.settings, key, value);
      });
    },

    //SEARCH / MARKING
    marked_order_id(state, id) {
      state.marked_order_id = id
      state.marked_orderrow_id = 0
      state.marked_orderplanning_id = 0
    },
    marked_orderrow_id(state, id) {
      state.marked_order_id = 0
      state.marked_orderrow_id = id
      state.marked_orderplanning_id = 0
    },
    marked_orderplanning_id(state, id) {
      state.marked_order_id = 0
      state.marked_orderrow_id = 0
      state.marked_orderplanning_id = id
    },
    searchvalue(state, value) {
      state.searchvalue = value
    },
    searchstatus(state, value) {
      state.searchstatus = value
    },
    searchdate(state, value) {
      state.searchdate = value
    },
    searchmanager(state, manager_id) {
      state.searchmanager = manager_id
    },
    searchresults(state, results) {
      state.searchresults = results
    },
    searching(state, value) {
      state.searching = value
    },

    roles(state, roles) {
      state.roles = new AccessControl(roles)
    },
  },
  actions: {
    //DATE ACTIONS
    async initialize({dispatch, state}) {
      //get the current user
      await dispatch('get_current_user')

      if (state.current_user != null) {
        dispatch('get_settings')

        dispatch('get_users')
        dispatch('get_workplaces')

        //set date to today
        var today = new Date()
        dispatch('set_date', {date: today})
        dispatch('get_unscheduled_orders')
      }
    },
    set_date({state, dispatch}, {date}) {
      //get monday from given date
      var d = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      var offset = d.getDay() - 1
      var newDate = d.getDate() - offset
      d.setDate(newDate)
      dispatch('clear_marked_orders')
      dispatch('get_planning', { date: d, num_columns: state.num_columns})
    },
    async get_planning({ commit }, { date, num_columns} ) {       
        let dateString = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()

        let uplanning = "/api/users/planning/" + dateString + "/" + num_columns
        let comments = "/api/workplaces/comments/" + dateString + "/" + num_columns
        let wplanning = "/api/orders/planning/" + dateString + "/" + num_columns
        let wcomments = "/api/weekcomment/" + dateString + "/" + num_columns
        let vacant_hours = "/api/workplaces/vacant/" + dateString

        const requestOne = axios.get(uplanning)
        const requestTwo = axios.get(comments)
        const requestThree = axios.get(wplanning)
        const requestFour = axios.get(wcomments)
        const requestFive = axios.get(vacant_hours)

        await axios.all([requestOne, requestTwo, requestThree, requestFour, requestFive]).then(axios.spread((...responses) => {
          var planning = {
            date: date,
            user_planning: responses[0].data,
            workplace_comments: responses[1].data,
            scheduled_orders: responses[2].data,
            week_comments: responses[3].data
          }
          commit('planning', planning)
          commit('workplace_vacant_hours', responses[4].data)

        })).catch(errors => {
          console.log(errors)
        })
    },

    get_workplace_vacant_hours({ commit, state }) {
      let dateString = state.date.getFullYear() + "/" + (state.date.getMonth() + 1) + "/" + state.date.getDate()
      axios.get("/api/workplaces/vacant/" + dateString).then((response) => {
        if (response && response.data) {
          commit('workplace_vacant_hours', response.data)
        }
      })
    },

    //LOGGED IN USER ACTIONS
    async get_current_user({state, commit}) {
      return axios.get("/api/user").then((response) => {
        if (state.current_user == null || response.data.user.id != state.current_user.id) {
          commit('current_user', response.data.user);
        }
      })
      .catch(() => {
        commit('current_user', null);
      });
    },
    async login({dispatch}, {username, password}) {
      return axios.post('/api/login', { username: username, password: password})
      .then(() => {
        dispatch('initialize')
      })
      .catch((response) => {
        console.log(response)
      })
    },
    logout({commit}) {
      axios.post('/api/logout').then((response) => {
        if (response && response.data) {
          commit('current_user', null)
        }
      })
    },

    //USERS DATA
    get_users({commit}) {
      //load all users and commit
      axios.get("/api/users/all")
      .then((response) => {
        commit('users', response.data)
      })
      .catch(() => {
      });
    },
    get_user_planning({ commit }, {startdate, numdays}) {
      var dateString = startdate.getFullYear() + "/" + (startdate.getMonth() + 1) + "/" + startdate.getDate()
      axios.get("/api/users/planning/" + dateString + "/" + numdays)
      .then((response) => {
        commit('user_planning', response.data);
      })
      .catch(() => {
      });
    },
    add_user({state}, user) {
      axios.put("/api/user/"+user.id, user).then((response) => {
        Vue.set(user, 'id', response.data.id)
        state.users.push(user)
        // Vue.set(state.users, response.data.id, response.data)
      })
    },
    save_user({getters}, user_id) {
      var user = getters.user_by_id(user_id)
      axios.put("/api/user/"+user_id, user)
    },
    delete_user({ getters }, user_id) {
      //get user and name
      var user = getters.user_by_id(user_id)
      var name = user.firstname
      if (user.middlename != undefined && user.middlename != '') {
        name = name + ' ' + user.middlename
      }
      name = name + ' ' + user.lastname

      //request confirm
      if (confirm('Weet je zeker dat je ' + name + ' wil verwijderen?')) {
        //post to backend
        axios.delete("/api/user/"+user_id)
        // .then(() => {
        //   //remove from users-list
        //   state.users.splice(user_id, 1)
        //   commit('users', state.users)
        // })
      }
    },
    
    //USER INTERACTIONS
    begin_drag_item({ commit }, {type, id, date, id2}) {
      commit('drag_data', { type: type, date: date, id: id, id2: id2 })
    },
    end_drag_item({ commit } ) {
      commit('drag_data', null)
    },

    //USER PLANNING
    save_user_planning({commit}, { user_id, date, planning } ) {
      //store the user planning locally
      commit('single_user_planning', { user_id: user_id, date: date, planning:planning } )

      var dateString = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
      axios.post("/api/user/"+user_id+"/planning/"+dateString, planning)
    },
    plan_user_hour({dispatch, getters}, { user_id, date, hour, type }) {
      //get current planning for this user
      if (getters.createAny('userplanning')) 
      {
        var planning = getters.user_planning_by_date(user_id, date)

        //check hour type
        var newPlanning = [];
        var value;

        //invert 'free' hours to available
        if (type == 0) {
          value = (planning[hour] == getters.hour_status_free || planning[hour] == getters.hour_status_blocked) ? getters.hour_status_available : getters.hour_status_free
        } else {
          value = type
        }

        //create new planning array
        planning.forEach(function(currentvalue, index) {
          if (index == hour) {
            newPlanning.push(value)
          } else {
            newPlanning.push(currentvalue)
          }
        });

        //store new planning
        dispatch('save_user_planning', {user_id: user_id, date: date, planning: newPlanning})
      }
    },
    plan_user_day({dispatch, getters}, { user_id, date, type }) {
      if (getters.createAny('userplanning')) {
        var planning = getters.user_planning_by_date(user_id, date)

        //free hours
        var newPlanning = [];
        if (type == getters.hour_status_free) {
          //check if we should invert
          var freePlanning = [getters.hour_status_free, getters.hour_status_free, getters.hour_status_free, getters.hour_status_free, getters.hour_status_free, getters.hour_status_free, getters.hour_status_free, getters.hour_status_free];
          // var availablePlanning = [getters.hour_status_available, getters.hour_status_available, getters.hour_status_available, getters.hour_status_available, getters.hour_status_available, getters.hour_status_available, getters.hour_status_available, getters.hour_status_available];
          
          if (JSON.stringify(freePlanning) == JSON.stringify(planning)) {
            newPlanning = default_planning;
          } else {
            newPlanning = freePlanning;
          }
        } else {

          //set hourtype for each available hour
          planning.forEach(function(hour) {
            if (hour != getters.hour_status_free && hour != getters.hour_status_blocked) {
              newPlanning.push(type);
            } else {
              newPlanning.push(hour);
            }
          });

          //is the new planning the same as the current planning?
          if (JSON.stringify(newPlanning) == JSON.stringify(planning)) {
            newPlanning.forEach(function(hour, index) {
              newPlanning[index] = hour == type ? getters.hour_status_available : hour;
            });
          }
        }
      }

      //store new planning
      dispatch('save_user_planning', {user_id: user_id, date: date, planning: newPlanning});
    },

    //WORKPLACES
    get_workplaces({commit}) {
      //load all users and commit
      axios.get("/api/workplaces/all")
      .then((response) => {
        commit('workplaces', response.data)
      })
      .catch(() => {
      })
    },
    add_workplace({state}, workplace) {
      axios.put("/api/workplace/"+workplace.id, workplace).then((response) => {
        Vue.set(workplace, 'id', response.data.id)
        state.workplaces.push(workplace)
      })
    },
    save_workplace({getters}, workplace_id) {
      var workplace = getters.workplace_by_id(workplace_id)
      axios.put("/api/workplace/"+workplace_id, workplace)
      // .then((response) => {
      // })
    },
    delete_workplace({state, commit, getters}, workplace_id) {
      //get workplace and name
      var workplace = getters.workplace_by_id(workplace_id)
      var name = workplace.name

      //request confirm
      if (confirm('Weet je zeker dat je ' + name + ' wil verwijderen?')) {
        //post to backend
        axios.delete("/api/workplace/"+workplace_id)
        //remove from workplaces-list
        state.workplaces.splice(workplace_id, 1)
        commit('workplaces', state.workplaces)
      }
    },

    //ORDERS
    get_unscheduled_orders({ commit }) {
      axios.get("/api/orders/unscheduled")
      .then((response) => {
        // state.unscheduled_orders = response.data
        commit('unscheduled_orders', response.data)
      })
    },
    plan_orderrow( { getters }, { workplace_id, orderrow_id, date, planning_id } ) {
      var planning_row
      if (planning_id > 0) {
        planning_row = getters.order_planning_by_planning_id(planning_id)
      } else {
        planning_row = getters.orderrow_by_orderrow_id(orderrow_id)
      }

      planning_row.date = date
      planning_row.previous_workplace_id = planning_row.planning_workplace_id
      planning_row.planning_workplace_id = workplace_id

      let rows = getters.order_planning_by_workplace_id_and_date(workplace_id, date)
      if (rows.length > 0)
        planning_row.planning_sort = rows[rows.length -1].planning_sort + 1
      else
        planning_row.planning_sort = 0

      //plan orderrow on date
      if (date != null) {
        var dateString = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
        axios.put("/api/orderrow/planning/"+dateString, {orderrow: planning_row})
      }
      //plan orderrow on temp-storage
      else 
      {
        axios.put("/api/orderrow/planning", {orderrow: planning_row})
      }
    },
    spread_orderrow({getters}, { hours, date, orderrow_id, workplaces }) {
      console.log(date)

      var d = new Date(date)
      if (d.getDay() == 0) d.setDate(d.getDate() + 1)
      
      var row = getters.orderrow_by_orderrow_id(orderrow_id)
      var remaining = JSON.parse(JSON.stringify(row.total_remaining))
      
      while (remaining > 0) {
        for (var i = 0; i < workplaces.length; ++i) {
          if (remaining > 0) {
            var planning_row = JSON.parse(JSON.stringify(row))

            planning_row.date = d
            planning_row.planning_workplace_id = workplaces[i]
            planning_row.total_remaining = Math.min(hours, remaining)

            var dateString = d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate()
            axios.put("/api/orderrow/planning/"+dateString, {orderrow: planning_row})

            remaining -= hours
          }
        }

        d.setDate(d.getDate() + 1);
        //skip sundays
        if (d.getDay() == 0) d.setDate(d.getDate() + 1);
      }
    },
    remove_orderrow_planning({ getters }, { planning_id }) {
      if (planning_id > 0) {
        var planning_row = getters.order_planning_by_planning_id(planning_id)
        if (planning_row != null)
          axios.delete("/api/orderrow/planning/"+planning_id)
      }
    },
    set_orderrow_planning_complete({ getters }, { planning_id }) {
      var orderrow_planning = getters.order_planning_by_planning_id(planning_id)
      axios.patch('/api/orderrows/planning/setcomplete', [orderrow_planning])
    },
    delete_orderrow(store, orderrow_id) {
      axios.delete('/api/orderrow/' + orderrow_id)
    },
    delete_order(store, order_id) {
      axios.delete('/api/order/' + order_id)
    },
    import_orders( { commit }) {
      commit('importing', true)

      axios.get("/api/import")
      .then(async (response) => {
        if (response.data != undefined && response.data.length > 0) {
          for (var i = 0; i < response.data.length; ++i) {
            await axios.put('/api/order', response.data[i])
          }
        }

        commit('importing', false)
      })
    },

    //SEARCH / MARKING
    search({ state, commit }) {
      commit('searchresults', [])
      commit('searching', true)
      var searchdata = {
          ordernumber: state.searchvalue,
          status: state.searchstatus,
          date: state.searchdate,
          manager: state.searchmanager
      }
      // axios.get('/api/orders/search/' + encodeURIComponent(this.searchvalue)).then((response) => {
      axios.post('/api/search', searchdata).then((response) => {
        commit('searchresults', response.data)
        commit('searching', false)
      })
    },
    clear_marked_orders({ commit }) {
      commit('marked_order_id', 0)
      commit('marked_orderrow_id', 0)
      commit('marked_orderplanning_id', 0)
    },
    clear_search({commit}) {
      commit('searchvalue', '')
      commit('searchstatus', [0, 1])
      commit('searchdate', '')
      commit('searchmanager', 0)
    },

    //COMMENTS
    get_workplaces_comments({commit}, {startdate, numdays}) {
      var dateString = startdate.getFullYear() + "/" + (startdate.getMonth() + 1) + "/" + startdate.getDate()
      axios.get("/api/workplaces/comments/" + dateString + "/" + numdays)
      .then((response) => {
        commit('workplace_comments', response.data)
      })
    },
    set_workplace_comment({commit}, {workplace_id, date, text}) {
      commit('workplace_comment', {workplace_id: workplace_id, date: new Date(date), text: text})

      var dateString = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
      axios.post("/api/workplace/"+workplace_id+"/comment/"+dateString, {comment: text})
    },
    set_week_comment({commit}, {date, text}) {
      commit('week_comment', {date: new Date(date), text: text})

      var dateString = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
      axios.post("/api/weekcomment/"+dateString, {comment: text})
    },

    //SETTINGS
    get_settings({commit}) {
      axios.get("/api/settings/")
      .then((response) => {
        commit('settings', response.data)
      })
      .catch(() => {
      })

      axios.get("/api/roles/")
      .then((response) => {
        commit('roles', response.data)
      })
      .catch(() => {
      })
    },
    save_settings({state, commit, dispatch}) {
      axios.patch("/api/settings/", state.settings)
      .then((response) => {
        commit('settings', response.data)
        dispatch('set_date', {date: state.date})
      })
      .catch(() => {
      })
    },
    ///todo: Implement this somewhere??
    reset_foreign_keys() {
      axios.patch("/api/reset_foreign_keys")
      .then((response) => {
        console.log(response)
      })
    },

    //SOCKET ACTIONS
    socket_userChanged({ state }, data) {
      axios.get("/api/user/"+data)
        .then((response) => {
          //do we have a record for the changed user?
          var index = state.users.findIndex(function(user) {
            return (user.id == data)
          })
          if (index > -1 && JSON.stringify(state.users[index]) != JSON.stringify(response.data) )
            Vue.set(state.users, index, response.data)
          else if (index == -1)
            state.users.push(response)
        })
    },
    socket_usersChanged({ dispatch }) {
      dispatch('get_users')
    },
    socket_userPlanningChanged({ commit, state, getters }, data) {
      //check if this day is visible
      var days = getters.days;
      var planning_date = new Date(data.date)
      var start_date = new Date(days[0])
      var end_date = new Date(days[days.length -1])

      if (planning_date.getTime() >= start_date.getTime() && planning_date.getTime() < end_date.getTime()) {
        //get current planning
        var day = state.user_planning[planning_date.getTime()] || []
        var currentplanning = day[data.user_id]
        
        //store the new planning
        if (!currentplanning || JSON.stringify(currentplanning.planning) != JSON.stringify(data.planning)) {
          data.date = new Date(data.date)
          commit('single_user_planning', data )
        }
      }
    },

    socket_unscheduledChanged({dispatch}) {
      //get unscheduled orders
      dispatch('get_unscheduled_orders')
    },
    socket_orderPlanned({ state, commit, dispatch }, data) {
      //do we have a record for the changed planning?
      var index = state.scheduled_orders.findIndex(function(item) {
        return (item.planning_id == data.planning_id)
      })

      //update the the existing record
      if (index > -1 && JSON.stringify(state.scheduled_orders[index]) != JSON.stringify(data) )
        Vue.set(state.scheduled_orders, index, data)
      else if (index == -1)
        state.scheduled_orders.push(data)

      //update target workplace
      if (data.planning_workplace_id) {
        axios.get("/api/workplace/"+data.planning_workplace_id)
          .then((response) => {
            commit('workplace', response.data)
          })
      }

      //update previous workplace
      if (data.previous_workplace_id && data.previous_workplace_id != data.planning_workplace_id) {
        axios.get("/api/workplace/"+data.previous_workplace_id)
          .then((response) => {
            commit('workplace', response.data)
          })
      }

      dispatch('get_workplace_vacant_hours')
    },
    socket_orderPlanningRemoved({ state }, data) {
      var index = state.scheduled_orders.findIndex(function(item) {
        return (item.planning_id == data)
      })

      if (index > -1 && JSON.stringify(state.scheduled_orders[index]) != JSON.stringify(data) )
        state.scheduled_orders.splice(index, 1)
    },
    socket_orderRemoved({state}, data) {
      var scheduledIndex = state.scheduled_orders.findIndex(row => row.order_id == data)
      while (scheduledIndex > -1) {
        state.scheduled_orders.splice(scheduledIndex, 1)
        scheduledIndex = state.scheduled_orders.findIndex(row => row.order_id == data)
      }

      var unscheduledIndex = state.unscheduled_orders.findIndex(row => row.order_id == data)
      while (unscheduledIndex > -1) {
        state.unscheduled_orders.splice(unscheduledIndex, 1)
        unscheduledIndex = state.unscheduled_orders.findIndex(row => row.order_id == data)
      }
    },
    socket_orderrowRemoved({state}, data) {
      var scheduledIndex = state.scheduled_orders.findIndex(row => row.orderrow_id == data)
      while (scheduledIndex > -1) {
        state.scheduled_orders.splice(scheduledIndex, 1)
        scheduledIndex = state.scheduled_orders.findIndex(row => row.orderrow_id == data)
      }

      var unscheduledIndex = state.unscheduled_orders.findIndex(row => row.orderrow_id == data)
      while (unscheduledIndex > -1) {
        state.unscheduled_orders.splice(unscheduledIndex, 1)
        unscheduledIndex = state.unscheduled_orders.findIndex(row => row.orderrow_id == data)
      }
    },

    socket_workplaceChanged({ state, dispatch }, data) {
      axios.get("/api/workplace/"+data)
        .then((response) => {
          //do we have a record for the changed workplace?
          var index = state.workplaces.findIndex(function(workplace) {
            return (workplace.id == data)
          })
          if (index > -1 && JSON.stringify(state.workplaces[index]) != JSON.stringify(response.data) )
            Vue.set(state.workplaces, index, response.data)
          else if (index == -1)
            state.workplaces.push(response)

          dispatch('get_workplace_vacant_hours')
        })
    },
    socket_workplacesChanged({ dispatch }) {
      dispatch('get_workplaces')
    },
    socket_workplaceCommentChanged({commit}, data) {
      commit('workplace_comment', {workplace_id: data.workplace_id, date: new Date(data.date), text: data.text})
    },

    socket_weekCommentChanged({commit}, comment) {
      commit('week_comment', {date: new Date(comment.date), text: comment.text})
    }
  },
  modules: {
  }
})
