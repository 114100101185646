//imports
import '@babel/polyfill'
import 'mutationobserver-shim'

//vue
import Vue from 'vue'
import './plugins/axios'
import './plugins/bootstrap-vue'
import './scss/main.scss'

//packages
import VueSocketIOExt from 'vue-socket.io-extended'
import io from 'socket.io-client'
import axios from 'axios';

//custom
import App from './App.vue'
import store from './store'

//font-awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

//vue-js-modals
import VModal from 'vue-js-modal'
Vue.use(VModal, { dynamic: true, dynamicDefaults: { 
  clickToClose: false,
  resizable: true,
  adaptive: true,
  minHeight: 200,
  minWidth: 400,
  width: '800px',
  height: '600px',
  draggable: '.popup-header',
} })

//Vue configuration
Vue.config.productionTip = false
//custom vue components
import Workplace from './components/Workplace'
Vue.component('Workplace', Workplace)
import GlobalEvents from 'vue-global-events'
Vue.component('GlobalEvents', GlobalEvents)

//Vue Filters
Vue.filter('yesno', function (value) {
  return value ? 'Ja' : 'Nee';
})

//set database remte DB and send it with axios headers
let remotedb = location.hostname.toLowerCase()

//SET API SETTINGS
if (isElectron()) {
  //Load config for electron
  const config = require('electron-json-config').factory()

  //create default config
  if (!config.has('API_PROTOCOL')) {
    config.setBulk({
        'API_PROTOCOL': 'https://',
        'API_HOST': 'localhost',
        'API_PORT': '12345',
        'REMOTEDB': 'localhost'
    })
    
    if (!config.has('REMOTEDB')) {
      config.set('REMOTEDB', 'localhost')
    }
  }

  //SET AXIOS URL
  axios.defaults.baseURL = config.get('API_PROTOCOL') + config.get('API_HOST') + ':' + config.get('API_PORT')

  //set remotedb to send in headers
  remotedb = config.get('REMOTEDB')
}

//setup socket connection
create_socket_connection(axios.defaults.baseURL)

//SET AXIOS DEFAULTS
axios.defaults.withCredentials = true
axios.defaults.headers.common['client'] = remotedb

//START VUE
new Vue({
  store,
  render: h => h(App),
  sockets: {
    connect: function () {
      this.$store.commit('server_online', true);
    },
    disconnect: function() {
      this.$store.commit('server_online', false);
    }
  },
}).$mount('#app')



/// HELPER METHOD TO DETECT IF WE'RE RUNNING IN ELECTRON OR NOT
function isElectron() {
  // Renderer process
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
      return true;
  }

  // Main process
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
      return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
      return true;
  }

  return false;
}

//PROTOTYPES
Date.prototype.getWeekNumber = function(){
  var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
}

//INIT VUE SOCKET
function create_socket_connection(host) {
  var options = {
    transports: ['polling'],
    withCredentials: true,
    transportOptions: {
      polling: {
        extraHeaders: {
          'client': remotedb
        }
      }
    }
  }
  const socket = io(host, options)
  Vue.use(VueSocketIOExt, socket, { store })
}
