<template>
  <div class="">
    <div class="form">
      <!-- ALGEMEEN -->
      <div class="card">
        <div class="card-header" id="order-conflict-settings" v-b-toggle="'collapse-order-conflict-settings'">
          Algemeen
        </div>
        <b-collapse id="collapse-order-conflict-settings" :visible="true">
          <div class="card-body">

            <!-- ODERREGELS OP DEZELFDE DAG -->
            <div class="form-row">
              <div class="col-3">
                <label for="">Orderregel volgorde op dezelfde dag</label>
              </div>
              <div class="col">
                <select class="form-control" v-model="settings.orderrow_order_conflict_compare" @change="save_settings">
                  <option value="0">Toon als conflict</option>
                  <option value="1">Toon niet als conflict</option>
                </select>
              </div>
            </div>

            <!-- Actieve week en verder -->
            <div class="form-row">
              <div class="col-3">
                <label for="">Actieve week en verder</label>
              </div>
              <div class="col">
                <div class="form-row">
                  <div class="custom-control custom-checkbox">
                      <input :disabled="loading" type="checkbox" class="custom-control-input" v-model="settings.show_activeweeks_remaining" @change="save_settings" id="show_activeweeks_remaining">
                      <label class="custom-control-label" for="show_activeweeks_remaining">Toon Actieve week en verder</label>
                  </div>
                </div>
              </div>
            </div>

            <!-- Totaal Openstaande uren -->
            <div class="form-row">
              <div class="col-3">
                <label for="">Totaal</label>
              </div>
              <div class="col">
                <div class="form-row">
                  <div class="custom-control custom-checkbox">
                      <input :disabled="loading" type="checkbox" class="custom-control-input" v-model="settings.show_total_remaining" @change="save_settings" id="show_total_remaining">
                      <label class="custom-control-label" for="show_total_remaining">Toon totaal openstaand</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <small>Openstaande uren per werkplek zijn enkel zichtbaar voor Hoofdplanners en Admins</small>
              </div>
            </div>

          </div>
        </b-collapse>
      </div>

      <!-- ORDERSTATUS IN/UITSCHAKELEN -->
      <div class="card">
        <div class="card-header" id="order-statusses" v-b-toggle="'collapse-order-statusses'">
          Orderstatussen
        </div>
        <b-collapse id="collapse-order-statusses" :visible="true">
          <div class="card-body">
            <p>
              Indien gewenst kunnen order-statussen worden uitgeschakeld in het planningsoverzicht.<br/>
              <em>Vinkje aan = status niet meer zichtbaar in planningoverzicht</em>
            </p>
            <div class="form-row order" v-for="status in settings.order_statusses" :key="'order_status_' + status.class" :class="status.class">
              <div class="custom-control custom-checkbox">
                  <input :disabled="loading" type="checkbox" class="custom-control-input" v-model="status.disabled" :id="'disabled-' + status.class" @change="save_settings">
                  <label class="custom-control-label" :for="'disabled-' + status.class">{{statusLabels[status.class]}} uitgeschakeld</label>
              </div>
            </div>

          </div>
        </b-collapse>
      </div>

      <!-- ORDERSTATUS IN/UITSCHAKELEN -->
      <div class="card">
        <div class="card-header" id="order-conditions" v-b-toggle="'collapse-order-conditions'">
          Order condities
        </div>
        <b-collapse id="collapse-order-conditions" :visible="true">
          <div class="card-body">
            <p>
              Indien gewenst kunnen order-condities worden uitgeschakeld in het planningsoverzicht.<br/>
              <em>Vinkje aan = conditie niet meer zichtbaar in planningoverzicht</em>
            </p>
            <div class="form-row order" v-for="condition in settings.order_conditions" :key="'order_condition_' + condition.class" :class="condition.class">
              <div class="custom-control custom-checkbox">
                  <input :disabled="loading" type="checkbox" class="custom-control-input" v-model="condition.disabled" :id="'disabled-' + condition.class" @change="save_settings">
                  <label class="custom-control-label" :for="'disabled-' + condition.class">{{condition.class}} uitgeschakeld</label>
              </div>
            </div>

          </div>
        </b-collapse>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "order-settings-screen",
  data() {
    return {
      statusLabels: {
        'order-completed': 'Afgerond',
        'order-priority': 'Spoed',
        'order-deliverydate-conflict': 'Leverdatum',
        'order-outsourcingdate-conflict': 'Uitbestedingsdatum',
        'order-ordering-conflict': 'Orderregel volgorde',
        'order-workplace-conflict': 'Werkplek'
      },
      conditionLabels: {
        'order-outsourcing': 'Uitbesteding',
        'order-pay-ahead': 'Vooruitbetalen'
      }
    };
  },
  computed: {
    ...mapGetters(["createAny", "readAny", "updateAny", "deleteAny"]),
    ...mapState([
      "loading",
      "users",
      "workplaces",
      "user_planning",
      "workplace_comments",
      "settings",
    ]),
  },
  methods: {
    ...mapActions(["save_settings"]),
  },
  components: {},
};
</script>