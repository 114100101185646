<template>
  <div class="login-form col-6 mt-4">
    <h1>Login</h1>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group id="usernamegroup" label="Gebruikersnaam" label-for="username" description="">
        <b-form-input id="username" type="text" v-model="form.username" required placeholder="Vul je gebruikersnaam in..."></b-form-input>
      </b-form-group>

      <b-form-group id="passwordgroup" label="Wachtwoord" label-for="password" description="">
        <b-form-input id="password" type="password" v-model="form.password" required placeholder="Vul je wachtwoord in..."></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary" class="mr-1" :disabled="!$store.state.server_online">Submit</b-button>
      <b-button type="reset" variant="danger" :disabled="!$store.state.server_online">Reset</b-button>

      <div class="mt-3">
        <b-alert variant="info" :show="api_error != null && !loading">{{api_error}}</b-alert>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// import axios from 'axios';
export default {
  name: 'login',
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
    }
  },
  computed: {
    ...mapState(['api_error', 'loading'])
  },
  methods: {
    ...mapActions([
      'login'
    ]),

    onSubmit (evt) {
      evt.preventDefault();

      this.login(this.form)
    },
    onReset (evt) {
      evt.preventDefault();

      /* Reset our form values */
      this.form.username = '';
      this.form.password = '';
    }
  },
  components: {},
  mounted() {
    this.$store.commit('api_error', undefined)
  }
}
</script>
