<template>
    <div class="sidebar">
        <div v-if="createAny('orderplanning')" 
            @click="show_unscheduled_orders_dialog()" 
            class="btn btn-primary btn-unscheduled-orders"
            @dragenter="on_enter_drag($event, null, null, 'unscheduled-orders')"
            @dragover="on_enter_drag($event, null, null, 'unscheduled-orders')">
                Inplannen
                <div
                    class="overlay"
                    :class="{ 'dragover': drag_over_element == 'unscheduled-orders' }" 
                    @dragover="on_over_drag($event, null, null)"
                    @dragleave="on_leave_drag()"
                    @drop="on_drop_item('workplace', null, null)">
                    
                </div>
        </div>
        <!-- v-bind:class="{'dragover': dragOverUnscheduledActive == true}" @dragover="dragOverUnscheduled($event)" @dragleave="dragLeaveUnscheduled($event)" @drop="dropUnscheduled($event)"> -->
        
        <!-- <TempStorageList /> -->
        <div v-for="workplace in temp_workplaces" :key="'temp-workplace-' + workplace.id">
            <div class="temp-storage">

                <div v-if="!workplace.temp_storage_collapsed" class="card"
                    @dragenter="on_enter_drag($event, null, null, 'temp-workplace-'+workplace.id)"
                    @dragover="on_enter_drag($event, null, null, 'temp-workplace-'+workplace.id)"
                    >

                    <div
                        class="overlay"
                        :class="{ 'dragover': drag_over_element == 'temp-workplace-'+workplace.id }" 
                        @dragover="on_over_drag($event, null, null)"
                        @dragleave="on_leave_drag()"
                        @drop="on_drop_item('workplace', workplace.id, null)"
                    />


                    <div class="card-header">
                        {{workplace.name}}
                    </div>

                    <div class="temp-storage-orders" style="min-height: 200px; max-height: 200px">

                        <div v-for="workplace_order in order_planning_by_workplace_id(workplace.id)" 
                            :key="'order-'+workplace_order.planning_id+'workplace'+workplace.id+'-planning'" 
                            class="order" 
                            :class="planned_order_classes(workplace_order)" 
                            :style="planned_order_padding(workplace_order)"
                            :draggable="is_order_draggable(workplace_order.order_id)" 

                            @contextmenu="$event.preventDefault(); $store.commit('marked_order_id', workplace_order.order_id)"

                            @dragstart="begin_drag_item({type: 'orderrow', id: workplace_order.orderrow_id, date: null, id2: workplace_order.planning_id })"
                            @dragend="end_drag_item()"
                            >

                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" :id="'order-'+workplace_order.planning_id+'-completed'" v-model="workplace_order.completed" @change="set_orderrow_planning_complete({ planning_id: workplace_order.planning_id } )">
                                <label class="custom-control-label" :for="'order-'+workplace_order.planning_id+'-completed'"></label>
                            </div>

                            <span class="order-number" @click="show_order_details(workplace_order)" :class="font_classes" :title="formatted_order_number(workplace_order)" v-html="formatted_order_number(workplace_order)"></span>
                            <span class="order-time" :contenteditable="createAny('orderplanning')" @blur="order_time_changed($event, workplace_order.planning_id)" @keydown.enter="$event.target.blur()" :class="font_classes">{{ workplace_order.planning_duration }}</span>

                            <div class="order-status" :title="workplace_order.new == 1 ? 'Nieuw' : workplace_order.changed == 1 ? 'Gewijzigd' : ''" :class="{new: workplace_order.new, changed: workplace_order.changed, concept: workplace_order.concept}"></div>
                            <div v-if="workplace_order.remarks != ''" class="order-comment"></div>
                        </div>


                        <!-- <draggable v-model="orderrows" @end="onSortEnd" class="day-orders" handle=".order-draggable"> -->
                            <!-- <WorkplaceOrder v-for="(n) in orderrows" v-bind:key="'order-' + n.planning_id" v-bind:workplace="workplace" v-bind:planning_id="n.planning_id" /> -->
                        <!-- </draggable> -->
                    </div>
                </div>

                <div v-else>
                    <div class="btn btn-primary btn-temp-storage"
                        @dragenter="on_enter_drag($event, null, null, 'temp-workplace-'+workplace.id)"
                        @dragover="on_enter_drag($event, null, null, 'temp-workplace-'+workplace.id)"
                        @click="show_workplace_orders(workplace.id)"
                        >

                        <div
                            class="overlay"
                            :class="{ 'dragover': drag_over_element == 'temp-workplace-'+workplace.id }" 
                            @dragover="on_over_drag($event, null, null)"
                            @dragleave="on_leave_drag()"
                            @drop="on_drop_item('workplace', workplace.id, null)"
                        />

                         <!-- @click="showOrderDialog" -->
                        {{workplace.name}}
                    </div>
                </div>
            </div>
        </div>

        <!-- MENU -->
        <div class="card">
            <div class="card-header" data-toggle="collapse" data-target="#task-list" aria-expanded="true">
                Taken
            </div>
            <ul class="list-group list-group-flush show" id="task-list">
                <a v-if="createAny('orders')" @click="show_order_details_modal()" class="list-group-item list-group-item-action"><font-awesome-icon icon="plus" />Order toevoegen</a>
                <a v-if="createAny('users')" @click="show_employees_modal()" class="list-group-item list-group-item-action"><font-awesome-icon icon="users" />Medewerkers</a>
                <a v-if="createAny('workplaces')" @click="show_workplaces_modal()" class="list-group-item list-group-item-action"><font-awesome-icon icon="map-marker" />Werkplekken</a>

                <a @click="show_search_modal()" class="list-group-item list-group-item-action"><font-awesome-icon icon="search" />Zoeken</a>
                <a @click="show_legenda_modal()" class="list-group-item list-group-item-action"><font-awesome-icon icon="list" />Legenda</a>
                <a @click="show_settings_modal()" class="list-group-item list-group-item-action"><font-awesome-icon icon="cogs" />Instellingen</a>
                <a @click="logout" class="list-group-item list-group-item-action"><font-awesome-icon icon="sign-out-alt" />Uitloggen</a>
            </ul>
        </div>

        <div class="card">
            <div class="card-header" data-toggle="collapse" data-target="#jump-to-date" aria-expanded="true">
                Spring naar datum
            </div>
            <ul class="list-group list-group-flush show" id="jump-to-date">
                <input type="date" class="form-control" v-model="jumpdate">
                <a @click="jump_to_date" class="list-group-item list-group-item-action"><font-awesome-icon icon="sign-out-alt" />Ga naar datum</a>
            </ul>
        </div>
        
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import DragDropTarget from './DragMixin'
import axios from 'axios'

import order_details_modal from './modals/OrderDetailsModal'
import settings_modal from './modals/SettingsModal'
import users_modal from './modals/UsersModal'
import workplaces_modal from './modals/WorkplacesModal'
import legenda_modal from './modals/LegendaModal'
import unscheduled_modal from './modals/UnscheduledModal'
import search_modal from './modals/SearchModal'
import workplace_orders_modal from './modals/WorkplaceOrdersModal'
import message_modal from './modals/MessageModal'

export default {
    name: 'sidebar',
    mixins: [DragDropTarget],
    data() {
        return {
            jumpdate: ""
        }
    },
    computed: {
    ...mapGetters([
      'createAny',
      'readAny',
      'updateAny',
            'deleteAny',
            
            'temp_workplaces',
            'order_planning_by_workplace_id',
            'planned_order_classes',
            'planned_order_padding',
            'is_order_draggable',
            'formatted_order_number',
            'font_classes',

            'order_planning_by_planning_id'
    ]),
    ...mapState([
    ])
  },
    methods: {
        ...mapActions([
            'logout',
            'begin_drag_item',
            'end_drag_item',
            'set_orderrow_planning_complete'
        ]),

        jump_to_date() {
            var wanted;
            if (this.jumpdate != "") {
                wanted = new Date(this.jumpdate);
            } else {
                wanted = new Date();
            }
            
            var d = new Date(wanted.getFullYear(), wanted.getMonth(), wanted.getDate());
            var offset = d.getDay() - 1;
            var newDate = d.getDate() - offset;
            d.setDate(newDate);

            this.$store.dispatch('set_date', { date: d });
        },
        show_legenda_modal() {
            this.$store.dispatch('clear_marked_orders')
            this.$modal.hide('legenda-modal')
            this.$modal.show(legenda_modal, {}, {
                name: 'legenda-modal',
            })
        },
        show_unscheduled_orders_dialog() {
            this.$store.dispatch('clear_marked_orders')
            this.$modal.hide('unscheduled-modal')
            this.$modal.show(unscheduled_modal, {}, {
                name: 'unscheduled-modal',
            })
        },
        show_order_details_modal() {
            this.$store.dispatch('clear_marked_orders')
            // this.$modal.hide('order-details-modal')
            this.$modal.show(order_details_modal, {}, {
                name: 'order-details-modal',
                width: '1280px',
                height: '800px'
            })
        },
        show_employees_modal() {
            this.$store.dispatch('clear_marked_orders')
            this.$modal.hide('users-modal')
            this.$modal.show(users_modal, {}, {
                name: 'users-modal',
            })
        },
        show_workplaces_modal() {
            this.$store.dispatch('clear_marked_orders')
            this.$modal.hide('workplaces-modal')
            this.$modal.show(workplaces_modal, {}, {
                name: 'workplaces-modal',
            })
        },
        show_settings_modal() {
            this.$store.dispatch('clear_marked_orders')
            this.$modal.hide('settings-modal')
            this.$modal.show(settings_modal, {}, {
                name: 'settings-modal',
            })
        },
        show_search_modal() {
            this.$store.dispatch('clear_marked_orders')
            this.$modal.hide('search-modal')
            this.$modal.show(search_modal, {}, {
                name: 'search-modal',
            })
        },
        show_workplace_orders(workplace_id) {
            this.$store.dispatch('clear_marked_orders')
            this.$modal.hide('workplace-orders-modal')
            this.$modal.show(workplace_orders_modal, {
                workplace_id: workplace_id
            }, {
                name: 'workplace-orders-modal',
            })
        },
        employeeWeeksOverviewDialog() {
        },
        workplaceWeeksOverviewDialog() {
        },

        show_order_details(planning_order) {
            this.$store.dispatch('clear_marked_orders')
            // this.$modal.hide('order-details-modal')
            axios.get('/api/order/' + planning_order.order_id).then((response) => {
                this.$modal.show(order_details_modal, {
                    order: response.data,
                    row: planning_order.orderrow_id
                }, {
                    name: 'order-details-modal',
                    width: '1280px',
                    height: '800px'
                })
            })
        },
        order_time_changed(event, planning_id) {
      var new_duration = parseFloat(event.target.innerText)
      var orderrow = this.order_planning_by_planning_id(planning_id)
      if (!isNaN(new_duration)) {
        var max_duration = parseFloat(orderrow.total_remaining) + parseFloat(orderrow.planning_duration)
        
        if (new_duration > max_duration) {
          //show error message
          this.$modal.show(message_modal, {
            title: 'Ongeldige tijd',
            message: 'Er is maximaal ' + max_duration + ' uur beschikbaar'
          })

          //revert to original time
          event.target.innerHTML = orderrow.planning_duration
        } else if (new_duration <= 0) {
          //show error message
          this.$modal.show(message_modal, {
            title: 'Ongeldige tijd',
            message: 'Voor een tijd hoger dan 0 in'
          })

          //revert to original time
          event.target.innerHTML = orderrow.planning_duration
        } else {
          Vue.set(orderrow, 'planning_duration', new_duration)
          this.$store.dispatch('plan_orderrow', { workplace_id: orderrow.planning_workplace_id, orderrow_id: orderrow.orderrow_id, date: new Date(orderrow.date), planning_id: orderrow.planning_id })
        }
      } else {
        //show error message
          this.$modal.show(message_modal, {
            title: 'Ongeldige tijd',
            message: 'Voer een geldig getal in'
          })

        //revert to original time
        event.target.innerHTML = orderrow.planning_duration
      }
    }
    },
    components: {},
    created: function() {
        
    }
}
</script>